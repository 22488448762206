import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button as BSButton, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import axios from '../utils/axios';
import Titulo from '../components/Shared/Titulo/Titulo';
import { FORM_ERRORS } from '../constants/mensajes';
import { detalleError } from '../utils/respuestas';

function NuevoVoto() {
  const [casillas, setCasillas] = useState([]);
  const [mensajeError, setMensajeError] = useState('');
  const history = useHistory();
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({ mode: 'onBlur' });

  const guardarVotos = async formData => {
    try {
      await axios.put(
        `Casilla/UpdateCasillaVotos/${formData.casilla}`,
        formData
      );
      history.push('/votos');
    } catch (ex) {
      setMensajeError(detalleError(ex)?.mensaje);
    }
  };

  const obtenerCasillas = async (idSeccion = 1) => {
    try {
      if (idSeccion) {
        const { data } = await axios.get(
          `Casilla/GetCasillasBySeccion/${idSeccion}`
        );
        setCasillas(data);
      }
    } catch (ex) {
      detalleError(ex);
    }
  };

  useEffect(() => {
    obtenerCasillas();
  }, []);

  return (
    <div>
      <Titulo titulo='Captura de Votos' ruta='/votos' regresar />
      {mensajeError && (
        <Alert
          className='mt-3'
          variant='danger'
          onClose={() => setMensajeError('')}
          dismissible>
          {mensajeError}
        </Alert>
      )}
      <Form className='mt-5' onSubmit={handleSubmit(guardarVotos)}>
        <div className='row mb-4'>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Sección</Form.Label>
              <Form.Control
                isInvalid={errors?.seccionId}
                name='seccionId'
                {...register('seccionId', {
                  required: FORM_ERRORS.REQUIRED,
                  valueAsNumber: true,
                })}
                onChange={({ target }) => obtenerCasillas(target.value)}
                type='number'
                defaultValue={1}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.seccionId?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Casilla</Form.Label>
              <Form.Control
                as='select'
                isInvalid={errors?.casilla}
                name='casilla'
                {...register('casilla', {
                  required: FORM_ERRORS.REQUIRED,
                  valueAsNumber: true,
                })}>
                <option value=''>Selecciona una casilla</option>
                {casillas.map(casilla => (
                  <option key={casilla.id} value={casilla.id}>
                    {casilla.casilla}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type='invalid'>
                {errors?.casilla?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </div>
        <div className='row mb-4 d-flex align-items-end justify-content-between'>
          <Col className='d-flex justify-content-center px-0' md={1}>
            <img
              src='/iconos/pan.png'
              style={{ marginBottom: '16px', width: '60px', height: '60px' }}
              alt='PAN'
            />
          </Col>
          <Col className='px-1' sm={12} md={3}>
            <Form.Group>
              <Form.Label>Votos PAN</Form.Label>
              <Form.Control
                isInvalid={errors?.pan}
                name='pan'
                {...register('pan', {
                  required: FORM_ERRORS.REQUIRED,
                  valueAsNumber: true,
                })}
                type='number'
                defaultValue={0}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.pan?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className='d-flex justify-content-center px-0' md={1}>
            <img
              src='/iconos/pri.png'
              style={{ marginBottom: '16px', width: '60px', height: '60px' }}
              alt='PAN'
            />
          </Col>
          <Col className='px-1' sm={12} md={3}>
            <Form.Group>
              <Form.Label>Votos PRI</Form.Label>
              <Form.Control
                isInvalid={errors?.pri}
                name='pri'
                {...register('pri', {
                  required: FORM_ERRORS.REQUIRED,
                  valueAsNumber: true,
                })}
                type='number'
                defaultValue={0}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.pri?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className='d-flex justify-content-center px-0' md={1}>
            <img
              src='/iconos/prd.png'
              style={{ marginBottom: '16px', width: '60px', height: '60px' }}
              alt='PAN'
            />
          </Col>
          <Col className='px-1' sm={12} md={3}>
            <Form.Group>
              <Form.Label>Votos PRD</Form.Label>
              <Form.Control
                isInvalid={errors?.prd}
                name='prd'
                {...register('prd', {
                  required: FORM_ERRORS.REQUIRED,
                  valueAsNumber: true,
                })}
                type='number'
                defaultValue={0}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.prd?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </div>
        <div className='row mb-4'>
          <Col
            className='d-flex justify-content-center align-items-end px-0'
            md={1}>
            <img
              src='/iconos/pvem.png'
              style={{ marginBottom: '16px', width: '60px', height: '60px' }}
              alt='PAN'
            />
          </Col>
          <Col className='px-1' sm={12} md={3}>
            <Form.Group>
              <Form.Label>Votos Partido Verde</Form.Label>
              <Form.Control
                isInvalid={errors?.verde}
                name='verde'
                {...register('verde', {
                  required: FORM_ERRORS.REQUIRED,
                  valueAsNumber: true,
                })}
                type='number'
                defaultValue={0}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.verde?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col
            className='d-flex justify-content-center align-items-end px-0'
            md={1}>
            <img
              src='/iconos/movciu.png'
              style={{ marginBottom: '16px', width: '60px', height: '60px' }}
              alt='PAN'
            />
          </Col>
          <Col className='px-1' sm={12} md={3}>
            <Form.Group>
              <Form.Label>Votos Movimiento Ciudadano</Form.Label>
              <Form.Control
                isInvalid={errors?.mc}
                name='mc'
                {...register('mc', {
                  required: FORM_ERRORS.REQUIRED,
                  valueAsNumber: true,
                })}
                type='number'
                defaultValue={0}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.mc?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col
            className='d-flex justify-content-center align-items-center px-0'
            md={1}>
            <img
              src='/iconos/panal.png'
              style={{ marginBottom: '0', width: '60px', height: '60px' }}
              alt='PAN'
            />
          </Col>
          <Col className='px-1' sm={12} md={3}>
            <Form.Group>
              <Form.Label>Votos Nueva Alianza</Form.Label>
              <Form.Control
                isInvalid={errors?.nuevA_ALIANZA}
                name='nuevA_ALIANZA'
                {...register('nuevA_ALIANZA', {
                  required: FORM_ERRORS.REQUIRED,
                  valueAsNumber: true,
                })}
                type='number'
                defaultValue={0}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.nuevA_ALIANZA?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </div>
        <div className='row mb-4'>
          <Col
            className='d-flex justify-content-center align-items-center px-0'
            md={1}>
            <img
              src='/iconos/morena.png'
              style={{ marginBottom: '14px', width: '60px', height: '60px' }}
              alt='PAN'
            />
          </Col>
          <Col className='px-1' sm={12} md={3}>
            <Form.Group>
              <Form.Label>Votos Morena</Form.Label>
              <Form.Control
                isInvalid={errors?.morena}
                name='morena'
                {...register('morena', {
                  required: FORM_ERRORS.REQUIRED,
                  valueAsNumber: true,
                })}
                type='number'
                defaultValue={0}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.morena?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </div>
        <div className='row d-flex align-items-center justify-content-end'>
          <Col sm={12} md={2}>
            <BSButton className='w-100' variant='secondary' type='submit'>
              Guardar
            </BSButton>
          </Col>
        </div>
      </Form>
    </div>
  );
}

export default NuevoVoto;

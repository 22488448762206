import React, { useState } from 'react';
import { Alert, Col, Form, Button, Table } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useForm } from 'react-hook-form';
import { Delete } from '@material-ui/icons';

import axios from '../utils/axios';
import { detalleError } from '../utils/respuestas';
import { FORM_ERRORS } from '../constants/mensajes';
import Titulo from '../components/Shared/Titulo/Titulo';

function AsignacionCasilla() {
  const [representantes, setRepresentantes] = useState([]);
  const [casillasAsignadas, setCasillasAsignadas] = useState([]);
  const [casillasFiltradas, setCasillasFiltradas] = useState([]);
  const [mensajeError, setMensajeError] = useState('');
  const [representanteGeneral, setRepresentanteGeneral] = useState({
    id: 0,
    fullName: '',
  });

  const {
    formState: { errors },
    getValues,
    register,
    handleSubmit,
  } = useForm({ mode: 'onBlur' });

  const obtenerCasillasRepresentante = async idRepresentante => {
    try {
      const { data } = await axios.get(
        `RepresentanteGeneral/GetRGById/${idRepresentante}`
      );
      setCasillasAsignadas(data.casillas);
    } catch (ex) {
      detalleError(ex);
    }
  };

  const representanteSeleccionado = representante => {
    if (representante.length) {
      const [resultante] = representante;
      setRepresentanteGeneral(resultante);
      obtenerCasillasRepresentante(resultante.id);
    }
  };

  const buscarRepresentantes = async representante => {
    try {
      const { data } = await axios.get(
        `RepresentanteGeneral/GetRGByFullName/${representante}`
      );
      setRepresentantes(data);
    } catch (ex) {
      detalleError(ex);
    }
  };

  const filtrarCasillas = async seccion => {
    try {
      if (seccion) {
        const { data } = await axios.get(
          `Casilla/GetCasillasBySeccion/${seccion}`
        );
        const filtroCasillas = data.filter(
          casilla => casilla.applicationUserId === null
        );
        setCasillasFiltradas(filtroCasillas);
      }
    } catch (ex) {
      detalleError(ex);
    }
  };

  const eliminarCasilla = async idCasilla => {
    try {
      const { data } = await axios.delete(
        `RepresentanteGeneral/DeleteCasillaRG/${representanteGeneral.id}/${idCasilla}`
      );
      setCasillasAsignadas(casillas =>
        casillas.filter(casilla => casilla.id !== idCasilla)
      );
      if (casillasFiltradas.length) {
        const [primerCasilla] = casillasFiltradas;
        if (data.seccionId === primerCasilla.seccionId) {
          setCasillasFiltradas(casillas => [...casillas, data]);
        }
      }
      if (!casillasFiltradas.length && getValues('seccion') > 0) {
        filtrarCasillas(getValues('seccion'));
      }
    } catch (ex) {
      setMensajeError(detalleError(ex)?.mensaje);
    }
  };

  const agregarCasilla = async formData => {
    try {
      const { data } = await axios.post(
        `RepresentanteGeneral/AddCasilla/${representanteGeneral.id}/${formData.casilla}`
      );
      setCasillasAsignadas(casillas => [...casillas, data]);
      filtrarCasillas(formData.seccion);
    } catch (ex) {
      setMensajeError(detalleError(ex)?.mensaje);
    }
  };

  return (
    <div>
      <Titulo titulo='Asignación de Casillas' />
      {mensajeError && (
        <div className='row'>
          <Col sm={12} md={10}>
            <Alert
              className='mt-3'
              variant='danger'
              onClose={() => setMensajeError('')}
              dismissible>
              {mensajeError}
            </Alert>
          </Col>
        </div>
      )}
      <Form.Group className='row'>
        <Col sm={12} md={4}>
          <Form.Label>Representante General</Form.Label>
          <AsyncTypeahead
            id='seleccionador-representante-general'
            labelKey='fullName'
            options={representantes}
            name='usuario'
            isLoading={false}
            onSearch={buscarRepresentantes}
            onChange={representanteSeleccionado}
            placeholder='Buscar Representante General'
          />
        </Col>
      </Form.Group>
      <div className='row'>
        <Col sm={12} md={4}>
          <h5 className='mb-1'>
            {representanteGeneral.fullName
              ? representanteGeneral.fullName
              : 'Nombre Representante General'}
          </h5>
        </Col>
      </div>
      <Form className='mt-3' onSubmit={handleSubmit(agregarCasilla)}>
        <Form.Group className='row'>
          <Col sm={12} md={4}>
            <Form.Label>Sección</Form.Label>
            <Form.Control
              isInvalid={errors?.seccion}
              name='seccion'
              {...register('seccion', {
                valueAsNumber: true,
                required: FORM_ERRORS.REQUIRED,
              })}
              type='number'
              onChange={({ target }) => filtrarCasillas(target.value)}
              defaultValue={0}
              readOnly={!representanteGeneral.fullName}
            />
            <Form.Control.Feedback type='invalid'>
              {errors?.seccion?.message}
            </Form.Control.Feedback>
          </Col>
          <Col sm={12} md={4}>
            <Form.Label>Casilla</Form.Label>
            <Form.Control
              as='select'
              isInvalid={errors?.casilla}
              name='casilla'
              {...register('casilla', {
                required: FORM_ERRORS.REQUIRED,
                valueAsNumber: true,
              })}
              readOnly={!representanteGeneral.fullName}>
              <option value=''>Selecciona una casilla</option>
              {casillasFiltradas.map(casilla => (
                <option key={casilla.id} value={casilla.id}>
                  {casilla.casilla}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              {errors?.casilla?.message}
            </Form.Control.Feedback>
          </Col>
          <Col className='d-flex align-items-end' sm={12} md={2}>
            <Button
              variant='secondary'
              type='submit'
              disabled={!representanteGeneral.fullName}
              block>
              Agregar Casilla
            </Button>
          </Col>
        </Form.Group>
      </Form>
      <div className='row mt-4'>
        <Col sm={12} md={10}>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Sección</th>
                <th>Casilla</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {casillasAsignadas.length ? (
                <>
                  {casillasAsignadas.map(casilla => (
                    <tr key={casilla.id}>
                      <td>{casilla.seccion}</td>
                      <td>{casilla.casilla}</td>
                      <td>
                        <Delete
                          style={{ cursor: 'pointer', display: 'block' }}
                          onClick={() => eliminarCasilla(casilla.id)}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan='3'>No hay casillas asignadas</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </div>
    </div>
  );
}

export default AsignacionCasilla;

import React from 'react';

import Titulo from '../../components/Shared/Titulo/Titulo';

export default function Eventos() {
  return (
    <>
      <Titulo titulo='Eventos' />
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { Col, Form, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Titulo from '../../components/Shared/Titulo/Titulo';
import { detalleError } from '../../utils/respuestas';
import axios from '../../utils/axios';

function Registro() {
  const [store, setStore] = useState([]);
  const [usuarios, setUsuarios] = useState([]);

  const buscarUsuario = usuario => {
    setUsuarios(store.filter(persona => persona.fullName.includes(usuario)));
  };

  const obtenerUsuarios = async () => {
    try {
      const { data } = await axios.get('Usuario/GetUsers');
      const indiceAdministrador = data.findIndex(
        usuario => usuario.fullName === null
      );
      data.splice(indiceAdministrador, 1);
      setStore(data);
      setUsuarios(data);
    } catch (ex) {
      detalleError(ex);
    }
  };

  useEffect(() => {
    obtenerUsuarios();
  }, []);

  return (
    <div className='registro'>
      <Titulo titulo='Registro de Usuarios' ruta='/' regresar />
      <div className='row d-flex align-items-center justify-content-between mb-3'>
        <Col sm={6} md={3}>
          <Form.Group>
            <Form.Control
              name='usuario'
              placeholder='Buscar usuario'
              onChange={({ target }) => buscarUsuario(target.value)}
            />
          </Form.Group>
        </Col>
        <Col sm={6} md={3} className='d-flex justify-content-end'>
          <Button as={Link} to='/usuarios/nuevo' variant='secondary'>
            Registrar Usuario
          </Button>
        </Col>
      </div>
      <div className='row'>
        <Col sm={12} md={12}>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Usuario</th>
                <th>Nombre</th>
                <th>Roles</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.length ? (
                <>
                  {usuarios.map(usuario => (
                    <tr key={usuario.id}>
                      <td>{usuario?.userName}</td>
                      <td>{usuario?.fullName}</td>
                      <td>{usuario.roles?.join(' - ')}</td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan='4'>No hay usuarios</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </div>
    </div>
  );
}

export default Registro;

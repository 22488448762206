import React, { useState } from 'react';
import { Row, Col, Form, Button, InputGroup, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import {
  Search,
  Assignment,
  Edit,
  Delete,
  SupervisorAccount,
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import Titulo from '../../components/Shared/Titulo/Titulo';

function Red() {
  const [itemBusqueda, setItemBusqueda] = useState('');

  const buscarUsuario = itemBuscado => {
    setItemBusqueda(itemBuscado);
  };

  return (
    <>
      <Titulo titulo='Estructura de Usuarios' />
      <Row className='mt-5'>
        <Col md={5}>
          <Form.Group>
            <InputGroup>
              <Form.Control
                name='itemBusqueda'
                value={itemBusqueda}
                onChange={({ target }) => buscarUsuario(target.value)}
                placeholder='Buscar clave electoral, nombre o celular'
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <Search fontSize='inherit' />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={7} className='d-flex justify-content-end align-items-start'>
          <Button variant='primary' as={NavLink} to='/registro-credencial'>
            Agregar Usuario
          </Button>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col md={12}>
          <h3 className='mb-3'>Coordinadores Municipales</h3>
          <Table striped hover>
            <thead>
              <tr>
                <th>Clave Electoral</th>
                <th>Nombre</th>
                <th>Celular</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>XXXXXXXXXXXXXX</td>
                <td>Jesús Alejandro Garcia</td>
                <td>312125874</td>
                <td>
                  <IconButton>
                    <Edit />
                  </IconButton>
                  <IconButton>
                    <Assignment />
                  </IconButton>
                  <IconButton>
                    <SupervisorAccount />
                  </IconButton>
                  <IconButton>
                    <Delete />
                  </IconButton>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}

export default Red;

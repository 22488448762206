import React from 'react';

import Titulo from '../../components/Shared/Titulo/Titulo';

export default function Mapas() {
  return (
    <>
      <Titulo titulo='Mapas' />
    </>
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import { Col, Form, Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import * as signalR from '@microsoft/signalr';
import { debounce } from 'lodash-es';

import Titulo from '../../components/Shared/Titulo/Titulo';
import { DEBOUNCE } from '../../constants/debounce';

import { detalleError } from '../../utils/respuestas';
import axios from '../../utils/axios';

import './Votos.scss';

function Votos() {
  const [votos, setVotos] = useState({});
  const [seccion, setSeccion] = useState(null);
  const [estado, setEstado] = useState(null);
  const [incidente, setIncidente] = useState(null);
  const [connection, setConnection] = useState(null);
  const history = useHistory();

  const goToBingo = (e, casillaId, seccionId) => {
    e.preventDefault();
    history.push(`/bingo/${casillaId}/${seccionId}`);
  };

  const obtenerVotos = async () => {
    try {
      const { data } = await axios.get(
        'Casilla/GetTwentyCasillasAndGlobalInfo'
      );
      setVotos(data);
    } catch (ex) {
      detalleError(ex);
    }
  };

  const buscarVotos = async (seccionParam, estadoParam, incidenteParam) => {
    try {
      const { data } = await axios.get(
        `Casilla/GetCasillasBySeccionAndFilter`,
        {
          params: {
            seccionId: seccionParam,
            estaAbierta: estadoParam,
            ponderacion: incidenteParam,
          },
        }
      );
      setVotos(data);
    } catch (ex) {
      detalleError(ex);
    }
  };

  const colorEstadoCasilla = (ponderacion, propiedad) => {
    if (ponderacion === 3) {
      return {
        [propiedad]: '#399FC6',
      };
    }
    if (ponderacion === 2) {
      return {
        [propiedad]: '#EECE3C',
      };
    }
    if (ponderacion === 1) {
      return {
        [propiedad]: '#B84242',
      };
    }
    return {
      [propiedad]: '#B84242',
    };
  };

  const textoPonderacion = ponderacion => {
    if (ponderacion === 1) return 'Alta';
    if (ponderacion === 2) return 'Media';
    return 'Baja';
  };

  const AbiertoCerradoCasilla = (casilla, propiedad) => {
    if (casilla.horaInicio && !casilla.horaCierre) {
      return {
        [propiedad]: '#28AD65',
      };
    }
    if (casilla.horaInicio && casilla.horaCierre) {
      return {
        [propiedad]: '#CE5353',
      };
    }
    return {
      [propiedad]: '#696969',
    };
  };

  const textoAbiertoCerradoCasilla = casilla => {
    if (casilla.horaInicio && !casilla.horaCierre) return 'Abierta';
    if (casilla.horaInicio && casilla.horaCierre) return 'Cerrada';
    return 'Inhabilitada';
  };

  const debounceFilter = useCallback(debounce(buscarVotos, DEBOUNCE.DELAY), []);

  useEffect(() => {
    if (seccion !== null || estado !== null || incidente !== null) {
      debounceFilter(seccion, estado, incidente);
    }
  }, [seccion, estado, incidente]);

  useEffect(() => {
    obtenerVotos();
    const signalrConnection = new signalR.HubConnectionBuilder()
      .withUrl('/dashBoardHub')
      .withAutomaticReconnect()
      .build();
    setConnection(signalrConnection);

    return () => {
      if (connection) {
        connection.off('IncidenteWasCreated');
        connection.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on('IncidenteWasCreated', data => {
            setVotos(oldData => ({ ...oldData, cantidadesGlobales: data }));
          });
        })
        .catch(err => console.error(err));
    }

    return () => {
      if (connection) {
        connection.off('IncidenteWasCreated');
        connection.stop();
      }
    };
  }, [connection]);

  return (
    <div className='votos'>
      <Titulo titulo='Casillas Electorales' />
      <h6 className='votos__subtitulo'>
        Total Casillas Cerradas{' '}
        {votos?.cantidadesGlobales?.totalCasillasCerradas} - Total Casillas
        Abiertas: {votos?.cantidadesGlobales?.totalCasillasAbiertas} - Total
        Incidentes: {votos?.cantidadesGlobales?.totalIncidentes}
      </h6>
      <div className='row d-flex align-items-end mt-4 mx-1'>
        <Col xs={12} sm={12} md={3}>
          <Form.Group>
            <Form.Control
              name='voto'
              onChange={({ target }) => setSeccion(target.value)}
              placeholder='Buscar Sección'
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={3}>
          <Form.Group>
            <Form.Label>Estado Casilla</Form.Label>
            <Form.Control
              name='estado'
              as='select'
              onChange={({ target }) => setEstado(target.value)}>
              <option value=''>Seleccione un estado</option>
              <option value='true'>Abiertas</option>
              <option value='false'>Cerradas</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={3}>
          <Form.Group>
            <Form.Label>Ponderación</Form.Label>
            <Form.Control
              name='incidentes'
              as='select'
              onChange={({ target }) => setIncidente(target.value)}>
              <option value=''>Seleccione una opción</option>
              <option value={1}>Alta</option>
              <option value={2}>Media</option>
              <option value={3}>Baja</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </div>
      <div className='row mt-4 votos__contenedor-casillas'>
        <Col sm={12} md={12} lg={12}>
          <div className='d-flex align-items-center justify-content-around flex-wrap votos__contenedor'>
            {votos?.casillas?.map(casilla => (
              <Card
                key={casilla.id}
                className='votos__card'
                as={Link}
                to={`/votos/${casilla.seccionId}/${casilla.casilla}`}>
                <div className='votos__cabecera-casilla'>
                  <div className='votos__estado-casilla'>
                    <div
                      className='votos__indicador-estado'
                      style={AbiertoCerradoCasilla(casilla, 'backgroundColor')}
                    />
                    <p
                      className='votos__texto-estado'
                      style={AbiertoCerradoCasilla(casilla, 'color')}>
                      {textoAbiertoCerradoCasilla(casilla)}
                    </p>
                  </div>
                  <div className='votos__ponderacion'>
                    <p
                      className='votos__texto-poderacion'
                      style={colorEstadoCasilla(casilla?.ponderacion, 'color')}>
                      {textoPonderacion(casilla?.ponderacion)}
                    </p>
                    <div
                      className='votos__indicador-ponderacion'
                      style={colorEstadoCasilla(
                        casilla?.ponderacion,
                        'backgroundColor'
                      )}
                    />
                  </div>
                </div>
                <p className='votos__numero-casilla'>{casilla.casilla}</p>
                <p className='votos__avance'>
                  Votantes: {casilla.meta} - Incidentes:{' '}
                  {casilla.cantidadIncidentes}
                </p>
                <div className='d-flex w-100 '>
                  <p className='votos__detalle-casilla m-auto'>
                    Sección {casilla.seccionId}
                  </p>
                  <button
                    type='button'
                    className='votos__btn-bingo m-auto btn btn-secondary p-1'
                    onClick={e => goToBingo(e, casilla.id, casilla.seccionId)}>
                    Bingo
                  </button>
                </div>
              </Card>
            ))}
          </div>
        </Col>
      </div>
    </div>
  );
}

export default Votos;

export const getFormData = data => {
  const formData = new FormData();

  Object.keys(data).forEach(key => {
    if (data[key]) {
      if (data[key].customOption) {
        formData.append(key, data[key].label);
      } else if (Array.isArray(data[key])) {
        data[key].forEach(value => formData.append(key, value));
      } else {
        formData.append(key, data[key]);
      }
    }
  });

  return formData;
};
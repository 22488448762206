import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Person, Search, LocationOn } from '@material-ui/icons';

import { autenticacionServicio } from '../../../services/atenticacion';

function Menu(props) {
  const { usuario, rol } = props;
  const cerrarSesion = () => autenticacionServicio.cerrarSesion();
  const nombreUsuario = (
    <>
      <Person /> {usuario}
    </>
  );

  return (
    <Navbar expand='lg' bg='primary' sticky='top' variant='dark'>
      <Navbar.Brand as={NavLink} to='/red'>
        Elecciones
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav' className='justify-content-end'>
        <Nav className='mr-auto'>
          <Nav.Link as={NavLink} to='/red'>
            Red
          </Nav.Link>
          <NavDropdown title='Reportes' id='basic-nav-dropdown'>
            <NavDropdown.Item as={NavLink} to='/reportes'>
              Reportes
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={NavLink} to='/estadisticas'>
            Estadisticas
          </Nav.Link>
          <Nav.Link as={NavLink} to='/eventos'>
            Eventos
          </Nav.Link>
          <NavDropdown title='Día D' id='basic-nav-dropdown'>
            {(rol === 'Administrador' || rol === 'Juridico') && (
              <>
                <NavDropdown.Item as={NavLink} to='/votos'>
                  Casillas
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to='/casillas/asignacion'>
                  Asignación Casillas
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to='/avance-bingo'>
                  Avance Bingo
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to='/avance'>
                  Conteo Rápido
                </NavDropdown.Item>
              </>
            )}
            {rol === 'Oro' && (
              <>
                <NavDropdown.Item as={NavLink} to='/votos'>
                  Casillas
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to='/avance-bingo'>
                  Avance Bingo
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to='/avance'>
                  Conteo Rápido
                </NavDropdown.Item>
              </>
            )}
            {rol === 'Bingo' && (
              <NavDropdown.Item as={NavLink} to='/avance-bingo'>
                Avance Bingo
              </NavDropdown.Item>
            )}
          </NavDropdown>
          <Nav.Link as={NavLink} to='/busqueda'>
            <Search /> Busqueda
          </Nav.Link>
          <Nav.Link as={NavLink} to='/mapas'>
            <LocationOn />
          </Nav.Link>
        </Nav>
        <Nav>
          <NavDropdown title={nombreUsuario} id='basic-nav-dropdown'>
            {rol === 'Administrador' && (
              <>
                <NavDropdown.Item as={NavLink} to='/usuarios'>
                  Usuarios
                </NavDropdown.Item>
                <NavDropdown.Divider />
              </>
            )}
            <NavDropdown.Item onClick={() => cerrarSesion()}>
              Cerrar Sesión
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

Menu.defaultProps = {
  rol: '',
  usuario: '',
};

Menu.propTypes = {
  usuario: PropTypes.string,
  rol: PropTypes.string,
};

export default Menu;

import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Col, Card } from 'react-bootstrap';

import TituloMovil from '../../../components/Shared/TituloMovil/TituloMovil';
import { VALORES_BINGO } from '../../../constants/representanteGeneral';
import { detalleError } from '../../../utils/respuestas';
import { useAutenticado } from '../../../hooks/useAutenticado';
import axios from '../../../utils/axios';

import './Bingo.scss';

function Bingo() {
  const { idCasilla } = useParams();
  const datosUsuario = JSON.parse(useAutenticado());
  const [mensajeError, setMensajeError] = useState('');
  const [elementoPantalla, setElementoPantalla] = useState(null);
  let numerosBingo = [];

  const enviarValorBingo = async bingo => {
    try {
      await axios.post(`Bingo/AddBingoToCasilla/${idCasilla}`, {
        numeroBingo: bingo,
      });
      numerosBingo = [];
      elementoPantalla.textContent = '';
    } catch (ex) {
      setMensajeError(detalleError(ex)?.mensaje);
    }
  };

  const clickBoton = valor => {
    if (typeof valor === 'string' && valor === 'AD') {
      const numeroSeleccionado = elementoPantalla.textContent;
      enviarValorBingo(+numeroSeleccionado);
    }

    if (typeof valor === 'string' && valor === 'CE') {
      numerosBingo = [];
      elementoPantalla.textContent = '';
      setMensajeError('');
    }

    if (typeof valor === 'number') {
      numerosBingo.push(valor);
      if (numerosBingo[0] === 0) numerosBingo.splice(0, 1);
      elementoPantalla.textContent = numerosBingo.join('');
    }
  };

  const fondoBotonesEspeciales = idBoton => {
    if (idBoton === 10) {
      return {
        backgroundColor: '#CE5353',
        color: '#FFFFFF',
      };
    }
    if (idBoton === 12) {
      return {
        backgroundColor: '#28AD65',
        color: '#FFFFFF',
      };
    }
    return {
      backgroundColor: '#B8B8B8',
    };
  };

  useEffect(() => {
    setElementoPantalla(document.querySelectorAll('.bingo__valor')[0]);
  }, []);

  return (
    <div className='bingo'>
      <TituloMovil titulo='Bingo' ruta={`/representante/${idCasilla}`} />
      <p className='bingo__subtitulo'>
        Representante General {datosUsuario?.usuario}
      </p>
      {mensajeError && (
        <div className='row'>
          <Col xs={12} sm={10} md={6}>
            <Alert
              className='mt-3'
              variant='danger'
              onClose={() => setMensajeError('')}
              dismissible>
              {mensajeError}
            </Alert>
          </Col>
        </div>
      )}
      <div className='row d-flex align-items-center justify-content-center mt-2'>
        <Col xs={12} sm={10} md={6}>
          <div className='bingo__calculadora'>
            <div className='bingo__pantalla'>
              <p className='bingo__valor' />
            </div>
            <div className='bingo__botones'>
              {VALORES_BINGO.map(item => (
                <Fragment key={item.id}>
                  <Card
                    className='bingo__boton'
                    onClick={() => clickBoton(item.valor)}
                    style={fondoBotonesEspeciales(item.id)}>
                    <p className='bingo__valor-boton'>{item.valor}</p>
                  </Card>
                </Fragment>
              ))}
            </div>
          </div>
        </Col>
      </div>
    </div>
  );
}

export default Bingo;

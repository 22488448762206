import { createWorker } from 'tesseract.js';

const worker = createWorker({
  logger: resp => console.log(resp),
});

const contenidoInformacion = contenido => {
  const [nombre, domicilio, cve, curp, estado, municipio, seccion] = contenido;
  const [, apellidoPaterno, apellidoMaterno, nombres] = nombre
    .split('NOMBRE')[1]
    .split('\n');
  return {
    nombre: nombres,
    apellidoPaterno,
    apellidoMaterno,
    domicilio: domicilio.split('DOMICILIO')[1],
    cve: cve.split('\n')[0],
    curp: curp.split('\n')[0],
    estado: estado.split('\n')[0].replace(/[^0-9 ]/g, ''),
    municipio: municipio.split('\n')[0].replace(/[^0-9 ]/g, ''),
    seccion: seccion.split('\n')[0].replace(/[^0-9 ]/g, ''),
  };
};

const bloques = [
  { left: 1138, top: 533, width: 677, height: 412 },
  { left: 1138, top: 533 + 413, width: 840, height: 430 },
  { left: 1138, top: 533 + 413 + 435, width: 1576, height: 159 },
  { left: 1138, top: 533 + 413 + 435 + 160, width: 1236, height: 159 },
  { left: 1138, top: 533 + 413 + 435 + 160 + 170, width: 457, height: 160 },
  {
    left: 1138 + 750,
    top: 533 + 413 + 435 + 160 + 170,
    width: 590,
    height: 160,
  },
  {
    left: 1138 + 800 + 590,
    top: 533 + 413 + 435 + 160 + 170,
    width: 590,
    height: 160,
  },
];

/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
export const readerCredencial = async imagenCredencial => {
  await worker.load();
  await worker.loadLanguage('eng');
  await worker.initialize('eng');
  await worker.setParameters({
    tessedit_char_whitelist: 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ0123456789 ',
  });
  let resultados = [];
  for (let indice = 0; indice < bloques.length; indice++) {
    const {
      data: { text },
    } = await worker.recognize(imagenCredencial, {
      rectangle: bloques[indice],
    });
    resultados.push(text);
  }
  console.log(resultados);
  await worker.terminate();
  return contenidoInformacion(resultados);
};

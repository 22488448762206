import React from 'react';
import { useParams } from 'react-router-dom';

import TituloMovil from '../../../components/Shared/TituloMovil/TituloMovil';

import './Incidentes.scss';

function Incidentes() {
  const { idCasilla } = useParams();
  const incidentes = [
    {
      id: 1,
      incidente: 'Un incidente de apertura',
    },
    {
      id: 2,
      incidente: 'Un incidente de apertura',
    },
    {
      id: 3,
      incidente: 'Un incidente de apertura',
    },
    {
      id: 4,
      incidente: 'Un incidente de apertura',
    },
    {
      id: 5,
      incidente: 'Un incidente de apertura',
    },
    {
      id: 6,
      incidente: 'Un incidente de apertura',
    },
    {
      id: 7,
      incidente: 'Un incidente de apertura',
    },
    {
      id: 8,
      incidente: 'Un incidente de apertura',
    },
  ];

  return (
    <div className='incidentes'>
      <TituloMovil titulo='Incidentes' ruta={`/representante/${idCasilla}`} />
      <div className='incidentes__lista'>
        {incidentes.map(incidente => (
          <div className='incidentes__item' key={incidente.id}>
            <p className='h6 mb-1'>{incidente.incidente}</p>
            <small className='d-block'>Tipo Incidente</small>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Incidentes;

import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import PropTypes from 'prop-types';

function Titulo({ ruta, regresar, titulo }) {
  return (
    <div className='d-flex align-items-center row my-4'>
      {regresar && (
        <Col sm={3} md={2}>
          <Button component={Link} startIcon={<ArrowBack />} to={ruta}>
            Regresar
          </Button>
        </Col>
      )}
      <Col sm={12} md={regresar ? 8 : 12}>
        <h2 className='text-center'>{titulo}</h2>
      </Col>
    </div>
  );
}

Titulo.defaultProps = {
  ruta: '/',
  regresar: false,
};

Titulo.propTypes = {
  ruta: PropTypes.string,
  regresar: PropTypes.bool,
  titulo: PropTypes.string.isRequired,
};

export default Titulo;

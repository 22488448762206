import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import TituloMovil from '../../../components/Shared/TituloMovil/TituloMovil';
import { useAutenticado } from '../../../hooks/useAutenticado';
import { OPCIONES_CASILLA } from '../../../constants/representanteGeneral';
import { detalleError } from '../../../utils/respuestas';
import axios from '../../../utils/axios';

import './OpcionesCasilla.scss';

function OpcionesCasilla() {
  const { idCasilla } = useParams();
  const datosUsuario = JSON.parse(useAutenticado());
  const [opciones, setOpciones] = useState([]);

  const obtenerCasillas = async () => {
    try {
      const { data } = await axios.get(`Casilla/GetCasillasByUser`);

      const estadoApertura = data.find(
        casilla => casilla.id === +idCasilla
      )?.horaInicio;
      const estadoCierre = data.find(
        casilla => casilla.id === +idCasilla
      )?.horaCierre;

      if (estadoApertura && estadoCierre) {
        setOpciones(
          OPCIONES_CASILLA.filter(item => item.opcion !== 'Apertura' && item.opcion !== 'Cierre')
        );
      } else if(estadoApertura){
        setOpciones(
          OPCIONES_CASILLA.filter(item => item.opcion !== 'Apertura')
        );
      } else if(estadoCierre)
      {
        setOpciones(
          OPCIONES_CASILLA.filter(item => item.opcion !== 'Cierre')
        );
      }

    } catch (ex) {
      detalleError(ex);
    }
  };

  useEffect(() => {
    setOpciones(OPCIONES_CASILLA);
    obtenerCasillas();
  }, []);

  return (
    <div className='opciones-casilla'>
      <TituloMovil titulo='Opciones Casilla' ruta='/representante' />
      <p className='opciones-casilla__subtitulo'>
        Representante General {datosUsuario?.usuario}
      </p>
      <div className='opciones-casilla__contenedor'>
        {opciones.map(opcion => (
          <Card
            className='opciones-casilla__opcion'
            key={opcion.id}
            as={Link}
            to={`/representante/${idCasilla}/${opcion.rutaOpcion}`}>
            <p className='opciones-casilla__texto-opcion'>{opcion.opcion}</p>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default OpcionesCasilla;

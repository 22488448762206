export const OPCIONES_CASILLA = [
  {
    id: 1,
    opcion: 'Apertura',
    rutaOpcion: 'apertura',
  },
  {
    id: 2,
    opcion: 'Jornada',
    rutaOpcion: 'jornada',
  },
  {
    id: 3,
    opcion: 'Bingo',
    rutaOpcion: 'bingo',
  },
  {
    id: 4,
    opcion: 'Votos',
    rutaOpcion: 'votos',
  },
  {
    id: 5,
    opcion: 'Cierre',
    rutaOpcion: 'cierre',
  },
];

export const OPCIONES_VOTOS = [
  {
    id: 1,
    tipo: 'Gobernador',
  },
  {
    id: 2,
    tipo: 'Diputado Federal',
  },
  {
    id: 3,
    tipo: 'Presidente',
  },
  {
    id: 4,
    tipo: 'Diputado Local',
  },
];

export const VALORES_BINGO = [
  {
    id: 1,
    valor: 1,
  },
  {
    id: 2,
    valor: 2,
  },
  {
    id: 3,
    valor: 3,
  },
  {
    id: 4,
    valor: 4,
  },
  {
    id: 5,
    valor: 5,
  },
  {
    id: 6,
    valor: 6,
  },
  {
    id: 7,
    valor: 7,
  },
  {
    id: 8,
    valor: 8,
  },
  {
    id: 9,
    valor: 9,
  },
  {
    id: 10,
    valor: 'CE',
  },
  {
    id: 11,
    valor: 0,
  },
  {
    id: 12,
    valor: 'AD',
  },
];

export const PARTIDOS = [
  {
    id: 1,
    llave: 'pan',
    label: 'PAN',
    imagen: '/iconos/pan.png',
  },
  {
    id: 2,
    llave: 'pri',
    label: 'PRI',
    imagen: '/iconos/pri.png',
  },
  {
    id: 3,
    llave: 'prd',
    label: 'PRD',
    imagen: '/iconos/prd.png',
  },
  {
    id: 4,
    llave: 'verde',
    label: 'Partido Verde',
    imagen: '/iconos/pvem.png',
  },
  {
    id: 5,
    llave: 'pt',
    label: 'Partido del Trabajo',
    imagen: '/iconos/pt.png',
  },
  {
    id: 6,
    llave: 'mc',
    label: 'Movimiento Ciudadano',
    imagen: '/iconos/movciu.png',
  },
  {
    id: 7,
    llave: 'rsp',
    label: 'Redes Sociales Progresistas',
    imagen: '/iconos/rsp.png',
  },
  {
    id: 8,
    llave: 'pes',
    label: 'Partido Encuentro Solidario',
    imagen: '/iconos/pes.png',
  },
  {
    id: 9,
    llave: 'fxm',
    label: 'Fuerza por México',
    imagen: '/iconos/fxm.png',
  },
  {
    id: 10,
    llave: 'paN_PRI',
    label: 'PAN/PRI',
    imagen: '/iconos/PAN_PRI.svg',
  },
  {
    id: 11,
    llave: 'paN_PRD',
    label: 'PAN/PRD',
    imagen: '/iconos/pan_prd.svg',
  },
  {
    id: 12,
    llave: 'prI_PRD',
    label: 'PRI/PRD',
    imagen: '/iconos/pri_prd.svg',
  },
  {
    id: 13,
    llave: 'paN_PRI_PRD',
    label: 'PAN/PRI/PRD',
    imagen: '/iconos/vaxcolima.png',
  },
  {
    id: 14,
    llave: 'morenA_NA',
    label: 'Morena/Nueva Alianza',
    imagen: '/iconos/morenana.png',
  },
  {
    id: 15,
    llave: 'candidaturA_INDEPENDIENTE',
    label: 'Candidatura Independiente',
    imagen: '/iconos/default.png',
  },
  {
    id: 16,
    llave: 'votoS_NULOS',
    label: 'Votos Nulos',
    imagen: '/iconos/nulo.png',
  },
  {
    id: 17,
    llave: 'candidatoS_NO_REGISTRADOS',
    label: 'Candidatos No Registrados',
    imagen: '/iconos/noregistrados.png',
  },
  {
    id: 18,
    llave: 'morena',
    label: 'Morena',
    imagen: '/iconos/morena.png',
  },
  {
    id: 19,
    llave: 'na',
    label: 'Nueva Alianza',
    imagen: '/iconos/panal.png',
  },
];

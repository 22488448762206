import React, { useState, useEffect } from 'react';
import { Col, Form, Button, Alert } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import TituloMovil from '../../../components/Shared/TituloMovil/TituloMovil';
import { useAutenticado } from '../../../hooks/useAutenticado';
import { detalleError } from '../../../utils/respuestas';
import { FORM_ERRORS } from '../../../constants/mensajes';
import LoadingOverlay from '../../../components/Shared/Loading/LoadingOverlay/LoadingOverlay';

import axios from '../../../utils/axios';
import { getFormData } from '../../../utils/FormData';

import './Jornada.scss';

function Jornada() {
  const history = useHistory();
  const { idCasilla } = useParams();
  const datosUsuario = JSON.parse(useAutenticado());
  const [catalogoIncidentes, setCatalogoIncidentes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mensajeError, setMensajeError] = useState('');

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({ mode: 'onBlur' });

  const obtenerCatalogoIncidentes = async () => {
    try {
      const { data } = await axios.get(`Incidente/GetIncidenteJornadaOptions`);
      const { incidencias } = data;
      if(incidencias)
        incidencias.unshift({id:0, descripcion:'Elige una incidencia'});

      setCatalogoIncidentes(incidencias);
    } catch (ex) {
      setMensajeError(detalleError(ex)?.mensaje);
    }
  };

  const abrirCasilla = async formData => {
    
    try {
      if(Number.isNaN(formData.incidenciaId) || formData.incidenciaId === 0)
        throw new Error('La incidencia no es válida');
    } catch(ex) {
      setMensajeError(ex?.message);
      return;
    }

    try {      
      setIsLoading(true);
      const dataJornada = getFormData({...formData, Files: [...formData.files]});
      await axios.post(
        `Incidente/AddIncidenteJornada/${idCasilla}`,
        dataJornada
      );
      history.push(`/representante/${idCasilla}`);
    } catch (ex) {
      setMensajeError(detalleError(ex)?.mensaje);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    obtenerCatalogoIncidentes();
  }, []);

  if(isLoading) return <LoadingOverlay />;

  return (
    <div className='jornada'>
      <TituloMovil titulo='Incidentes' ruta={`/representante/${idCasilla}`} />
      <p className='jornada__subtitulo'>
        Representante General {datosUsuario?.usuario}
      </p>
      {mensajeError && (
        <div className='row'>
          <Col sm={12} md={10}>
            <Alert
              className='mt-3'
              variant='danger'
              onClose={() => setMensajeError('')}
              dismissible>
              {mensajeError}
            </Alert>
          </Col>
        </div>
      )}
      <Form onSubmit={handleSubmit(abrirCasilla)}>
        <div className='row mt-3 d-flex align-items-center justify-content-center'>
          <Col xs={11} sm={11} md={4}>
            <Form.Group>
              <Form.Label>Hora Incidente</Form.Label>
              <Form.Control
                isInvalid={errors?.hora}
                name='hora'
                type='time'
                {...register('hora', {
                  required: FORM_ERRORS.REQUIRED,
                })}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.hora?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </div>
        <div className='row mt-2 d-flex align-items-center justify-content-center'>
          <Col xs={11} sm={11} md={4}>
            <Form.Group>
              <Form.Label>Incidente</Form.Label>
              <Form.Control
                name='incidenciaId'
                as='select'                
                {...register('incidenciaId', {
                  valueAsNumber: true,
                })}>
                {catalogoIncidentes?.map(incidente => (
                  <option key={incidente.id} value={incidente.id}>
                    {incidente.descripcion}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </div>
        <div className='row mt-2 d-flex align-items-center justify-content-center'>
          <Col xs={11} sm={11} md={4}>
            <Form.Group controlId='imagenesIncidentes'>
              <Form.Label>Imágenes Incidentes</Form.Label>
              <Form.Control
                accept='image/*'
                name='files'
                {...register('files')}
                type='file'
                multiple
              />
            </Form.Group>
          </Col>
        </div>
        <div className='row mt-2 d-flex align-items-center justify-content-center'>
          <Col xs={11} sm={11} md={4}>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                name='descripcion'
                as='textarea'
                {...register('descripcion')}
                rows={4}
              />
            </Form.Group>
          </Col>
        </div>
        <div className='row mt-2 d-flex align-items-center justify-content-center'>
          <Col xs={11} sm={11} md={4}>
            <Button variant='secondary' type='submit' block>
              Guardar Incidente
            </Button>
          </Col>
        </div>
      </Form>
    </div>
  );
}

export default Jornada;

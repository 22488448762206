import React, { useState } from 'react';
import { Alert, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import axios from '../../utils/axios';
import Titulo from '../../components/Shared/Titulo/Titulo';
import { FORM_ERRORS } from '../../constants/mensajes';
import { detalleError } from '../../utils/respuestas';

function NuevoUsuario() {
  const [mensajeError, setMensajeError] = useState('');
  const history = useHistory();
  const {
    formState: { errors },
    getValues,
    register,
    handleSubmit,
  } = useForm({ mode: 'onBlur' });

  const registrarUsuario = async formData => {
    const roles = formData.roles.map(rol => {
      return { name: rol, selected: true };
    });
    const dataUsuario = { ...formData, roles };
    try {
      await axios.post('Usuario/registraUsuario', dataUsuario);
      history.push('/usuarios');
    } catch (ex) {
      setMensajeError(detalleError(ex)?.mensaje);
    }
  };

  const validarRoles = () =>
    getValues('roles').length ? true : FORM_ERRORS.AT_LEAST_ONE;

  return (
    <div className='nuevo-usuario'>
      <Titulo titulo='Nuevo Usuario' ruta='/usuarios' regresar />
      {mensajeError && (
        <div className='row d-flex justify-content-center'>
          <Col sm={12} md={5}>
            <Alert
              className='mt-3'
              variant='danger'
              onClose={() => setMensajeError('')}
              dismissible>
              {mensajeError}
            </Alert>
          </Col>
        </div>
      )}
      <Form onSubmit={handleSubmit(registrarUsuario)}>
        <Form.Group className='row d-flex justify-content-center'>
          <Col sm={12} md={5}>
            <Form.Label>Usuario</Form.Label>
            <Form.Control
              isInvalid={errors?.userName}
              name='userName'
              {...register('userName', {
                required: FORM_ERRORS.REQUIRED,
              })}
            />
            <Form.Control.Feedback type='invalid'>
              {errors?.userName?.message}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group className='row d-flex justify-content-center'>
          <Col sm={12} md={5}>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              isInvalid={errors?.fullName}
              name='fullName'
              {...register('fullName', {
                required: FORM_ERRORS.REQUIRED,
              })}
            />
            <Form.Control.Feedback type='invalid'>
              {errors?.fullName?.message}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group className='row d-flex justify-content-center'>
          <Col sm={12} md={5}>
            <Form.Label>Roles</Form.Label>
            <Form.Check>
              <>
                <Form.Check.Input
                  isInvalid={errors?.roles}
                  name='roles'
                  type='checkbox'
                  value='Administrador'
                  {...register('roles', {
                    validate: validarRoles,
                  })}
                />
                <Form.Check.Label>Administrador</Form.Check.Label>
                <Form.Control.Feedback type='invalid'>
                  {errors?.roles?.message}
                </Form.Control.Feedback>
              </>
              <div className='mt-2'>
                <Form.Check.Input
                  isInvalid={errors?.roles}
                  name='roles'
                  type='checkbox'
                  value='Representante General'
                  {...register('roles', {
                    validate: validarRoles,
                  })}
                />
                <Form.Check.Label>Representante General</Form.Check.Label>
                <Form.Control.Feedback type='invalid'>
                  {errors?.roles?.message}
                </Form.Control.Feedback>
              </div>
              <div className='mt-2'>
                <Form.Check.Input
                  isInvalid={errors?.roles}
                  name='roles'
                  type='checkbox'
                  value='Juridico'
                  {...register('roles', {
                    validate: validarRoles,
                  })}
                />
                <Form.Check.Label>Juridico</Form.Check.Label>
                <Form.Control.Feedback type='invalid'>
                  {errors?.roles?.message}
                </Form.Control.Feedback>
              </div>
              <div className='mt-2'>
                <Form.Check.Input
                  isInvalid={errors?.roles}
                  name='roles'
                  type='checkbox'
                  value='Bingo'
                  {...register('roles', {
                    validate: validarRoles,
                  })}
                />
                <Form.Check.Label>Bingo</Form.Check.Label>
                <Form.Control.Feedback type='invalid'>
                  {errors?.roles?.message}
                </Form.Control.Feedback>
              </div>
              <div className='mt-2'>
                <Form.Check.Input
                  isInvalid={errors?.roles}
                  name='roles'
                  type='checkbox'
                  value='Oro'
                  {...register('roles', {
                    validate: validarRoles,
                  })}
                />
                <Form.Check.Label>Oro</Form.Check.Label>
                <Form.Control.Feedback type='invalid'>
                  {errors?.roles?.message}
                </Form.Control.Feedback>
              </div>
            </Form.Check>
          </Col>
        </Form.Group>
        <div className='row d-flex justify-content-center'>
          <Col sm={12} md={5}>
            <Button type='submit' variant='secondary' block>
              Guardar
            </Button>
          </Col>
        </div>
      </Form>
    </div>
  );
}

export default NuevoUsuario;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Form, Table, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import Titulo from '../../../components/Shared/Titulo/Titulo';
import { FORM_ERRORS } from '../../../constants/mensajes';
import { detalleError } from '../../../utils/respuestas';
import { useAutenticado } from '../../../hooks/useAutenticado';
import axios from '../../../utils/axios';

import './DetalleCasilla.scss';

function DetalleCasilla() {
  const { casilla, idSeccion } = useParams();
  const [detalleCasilla, setDetalleCasilla] = useState({});
  const [incidentes, setIncidentes] = useState([]);
  const ponderacion = { 1: 'Alta', 2: 'Media', 3: 'Baja' };
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({ mode: 'onBlur' });

  const formatoHora = hora => {
    if (hora) {
      if (typeof hora === 'string') {
        const [hours, minutes] = hora.split(':');
        return dayjs()
          .set('hour', hours)
          .set('minute', minutes)
          .format('h:mm A');
      }
      const { hours, minutes } = hora;
      return dayjs().set('hour', hours).set('minute', minutes).format('h:mm A');
    }
    return '00:00 A.M.';
  };

  const descargarArchivo = enlaceArchivo => {
    const enlace = document.createElement('a');
    enlace.setAttribute('href', enlaceArchivo);
    enlace.setAttribute('download', 'evidencias.zip');
    document.body.appendChild(enlace);
    enlace.click();
    window.URL.revokeObjectURL(enlaceArchivo);
    enlace.remove();
  };

  const rolUsuario = () => JSON.parse(useAutenticado())?.roles.includes('Oro');

  const descargaEvidencias = async idEvidencia => {
    try {
      const { data } = await axios.get(`Evidencia/DownloadZip/${idEvidencia}`, {
        responseType: 'blob',
      });
      const rutaArchivoZip = window.URL.createObjectURL(data);
      descargarArchivo(rutaArchivoZip);
    } catch (ex) {
      detalleError(ex);
    }
  };

  const descargaEvidenciasCierre = async () => {
    try {
      const { data } = await axios.get(
        `Evidencia/DownloadCierreZip/${detalleCasilla.id}`,
        {
          responseType: 'blob',
        }
      );
      const rutaArchivoZip = window.URL.createObjectURL(data);
      descargarArchivo(rutaArchivoZip);
    } catch (ex) {
      detalleError(ex);
    }
  };

  const obtenerEvidencias = async idCasilla => {
    try {
      const { data } = await axios.get(
        `Incidente/GetIncidentesByCasillaId/${idCasilla}`
      );
      setIncidentes(data);
    } catch (ex) {
      detalleError(ex);
    }
  };

  const cambiarPonderacion = async formData => {
    try {
      await axios.put(
        `Casilla/UpdateCasillaPonderacion/${detalleCasilla.id}`,
        formData
      );
      setDetalleCasilla(detalle => {
        return { ...detalle, ponderacion: formData.ponderacion };
      });
    } catch (ex) {
      detalleError(ex);
    }
  };

  const obtenerCasillas = async () => {
    try {
      const { data } = await axios.get(
        `Casilla/GetCasillasBySeccion/${idSeccion}`
      );
      const casillaEncontrada = data.find(item => item.casilla === casilla);
      obtenerEvidencias(casillaEncontrada.id);
      setDetalleCasilla(casillaEncontrada);
    } catch (ex) {
      detalleError(ex);
    }
  };

  useEffect(() => {
    obtenerCasillas();
  }, []);

  return (
    <div className='detalle-casilla'>
      <Titulo titulo={`Detalle Casilla ${casilla}`} ruta='/votos' regresar />
      <h4 className='text-center'>Sección {idSeccion}</h4>
      <div className='row mt-4 detalle-casilla__informacion'>
        <Col xs={12} sm={6} md={6}>
          <h4 className='text-center mb-4'>Información Casilla</h4>
          <p className='font-weight-bold'>
            Municipio:{' '}
            <span className='font-weight-normal'>
              {detalleCasilla.municipio}
            </span>
          </p>
          <p className='font-weight-bold'>
            Distrito Federal:{' '}
            <span className='font-weight-normal'>
              {detalleCasilla.distritoFederalId}
            </span>
          </p>
          <p className='font-weight-bold'>
            Distrito Local:{' '}
            <span className='font-weight-normal'>
              {detalleCasilla.distritoLocalId}
            </span>
          </p>
          <p className='font-weight-bold'>
            Hora Apertura:{' '}
            <span className='font-weight-normal'>
              {formatoHora(detalleCasilla.horaInicio)}
            </span>
          </p>
          <p className='font-weight-bold'>
            Hora Cierre:{' '}
            <span className='font-weight-normal'>
              {formatoHora(detalleCasilla.horaCierre)}
            </span>
          </p>
        </Col>
        <Col xs={12} sm={6} md={6}>
          <h4 className='text-center mb-4'>Estado Casilla</h4>
          <p className='font-weight-bold'>
            Ponderación:{' '}
            <span className='font-weight-normal'>
              {ponderacion[detalleCasilla.ponderacion]}
            </span>
          </p>
          <p className='font-weight-bold'>
            Total votantes:{' '}
            <span className='font-weight-normal'>{detalleCasilla.meta}</span>
          </p>
          <p className='font-weight-bold'>
            Incidentes:{' '}
            <span className='font-weight-normal'>
              {detalleCasilla.cantidadIncidentes}
            </span>
          </p>
        </Col>
      </div>
      {!rolUsuario() && (
        <Row>
          <Col xs={12} sm={12} md={9}>
            <Form
              className='row d-flex align-items-end'
              onSubmit={handleSubmit(cambiarPonderacion)}>
              <Col xs={12} sm={12} md={4}>
                <Form.Group className='m-0'>
                  <Form.Label>Ponderación</Form.Label>
                  <Form.Control
                    isInvalid={errors?.ponderacion}
                    as='select'
                    name='ponderacion'
                    {...register('ponderacion', {
                      required: FORM_ERRORS.REQUIRED,
                      valueAsNumber: true,
                    })}
                    defaultValue={detalleCasilla.ponderacion}>
                    <option value=''>Seleccionar ponderación</option>
                    <option value={3}>Baja</option>
                    <option value={2}>Media</option>
                    <option value={1}>Alta</option>
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    {errors?.ponderacion?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8}>
                <Button variant='secondary' type='submit'>
                  Cambiar Ponderación
                </Button>
              </Col>
            </Form>
          </Col>
          <Col xs={12} sm={12} md={3} className='align-self-end'>
            {detalleCasilla.horaCierre && (
              <Button
                variant='secondary'
                onClick={() => descargaEvidenciasCierre()}>
                Descargar evidencias de cierre
              </Button>
            )}
          </Col>
        </Row>
      )}
      <div className='row mt-3 detalle-casilla__contenedor-tabla'>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th>Incidencia</th>
              <th>Hora</th>
              <th>Descripción</th>
              <th>Evidencias</th>
            </tr>
          </thead>
          <tbody>
            {incidentes.map(incidente => (
              <tr key={incidente.id}>
                <td>{incidente.incidencia}</td>
                <td>{formatoHora(incidente.hora)}</td>
                <td>{incidente.descripcion}</td>
                <td>
                  {incidente.tieneEvidencias && (
                    <Button
                      variant='secondary'
                      onClick={() => descargaEvidencias(incidente.id)}>
                      Descargar Evidencias
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default DetalleCasilla;

import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAutenticado } from '../hooks/useAutenticado';

function RutaPrivada({ children, titulo, ...rest }) {
  const autenticado = useAutenticado();

  const renderizado = ({ location }) => {
    if (autenticado) return children;
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    );
  };

  useEffect(() => {
    document.title = titulo;
  }, [titulo]);

  return <Route {...rest} render={renderizado} />;
}

RutaPrivada.defaultProps = {
  titulo: '',
};

RutaPrivada.propTypes = {
  titulo: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default RutaPrivada;

import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useFormik } from 'formik';
import Webcam from 'react-webcam';

import Titulo from '../../components/Shared/Titulo/Titulo';
// import CredencialTemplate from '../../components/Shared/Imagen/Imagen';
import { validacionesRegistroCredencial } from '../../utils/validaciones';
import { readerCredencial } from '../../utils/ocrReader';

import './RegistroCredencial.scss';

export default function RegistroCredencial() {
  const [imagenCredencial, setImagenCredencial] = useState(null);
  const credencialRef = useRef(null);
  const camaraRef = useRef(null);
  const webcamRef = useRef(null);
  const formik = useFormik({
    initialValues: {
      cve: '',
      seccion: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      nombre: '',
      codigoPostal: '',
      estado: '',
      municipio: '',
      colonia: 1,
      calle: '',
      numeroExterior: '',
      numeroInterior: '',
      telefono: '',
    },
    validationSchema: validacionesRegistroCredencial,
    onSubmit: values => {
      console.log(values);
      formik.setValues({});
    },
  });

  /* const cargarFotoCredencial = () => {
    if (credencialRef) {
      const { current } = credencialRef;
      current.click();
    } else if (camaraRef) {
      const { current } = camaraRef;
      current.click();
    }
  }; */

  const leerImagenCredencial = async foto => {
    try {
      if (foto) {
        const {
          cve,
          nombre,
          apellidoPaterno,
          apellidoMaterno,
          seccion,
        } = await readerCredencial(foto);
        formik.setFieldValue('cve', cve);
        formik.setFieldValue('nombre', nombre);
        formik.setFieldValue('apellidoPaterno', apellidoPaterno);
        formik.setFieldValue('apellidoMaterno', apellidoMaterno);
        formik.setFieldValue('seccion', seccion);
      } else {
        throw Error('No se pudo capturar la fotografía');
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const recuperarFotoCredencial = ({ target }) => {
    const { files } = target;
    if (files.length) {
      const fotoRecuperada = files.item(0);
      const reader = new FileReader();
      reader.onloadend = async () => {
        setImagenCredencial(
          <img
            alt='Credencial Electoral'
            src={reader.result}
            width={360}
            height={280}
          />
        );
      };
      reader.readAsDataURL(fotoRecuperada);
    }
  };

  const videoConstraints = {
    width: 360,
    height: 280,
    facingMode: 'user',
  };

  // Obtener imagen capturada por la webcam
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImagenCredencial(
      <img alt='Credencial Electoral' src={imageSrc} width={360} height={280} />
    );
    leerImagenCredencial(imageSrc);
  }, [webcamRef]);

  const bordeCamaraWeb = () => {
    const canvas = document.querySelector('#borde');
    canvas.width = 250;
    canvas.height = 160;
    const context = canvas.getContext('2d');
    context.strokeStyle = 'red';
    context.strokeRect(0, 0, 250, 200);
  };

  useEffect(() => {
    bordeCamaraWeb();
  }, []);

  return (
    <div className='registro-credencial'>
      <Titulo titulo='Registro Coordinador' ruta='/red' regresar />
      <form onSubmit={formik.handleSubmit}>
        <div className='regitro-credencial__seccion'>
          <div className='registro-credencial__cabecera'>
            <p className='registro-credencial__titulo-cabecera'>Credencial</p>
          </div>
          <Row className='mt-3'>
            <Col
              md={6}
              className='d-flex align-items-center justify-content-center'>
              <div className='registro-credencial__foto-credencial'>
                {!imagenCredencial ? (
                  <div className='registro-credencial__contenedor-webcam'>
                    <Webcam
                      audio={false}
                      height={280}
                      ref={webcamRef}
                      screenshotFormat='image/jpeg'
                      width={360}
                      videoConstraints={videoConstraints}
                    />
                    <canvas
                      className='registro-credencial__borde-credencial'
                      id='borde'
                    />
                  </div>
                ) : (
                  imagenCredencial
                )}
                <div className='d-flex align-items-center'>
                  {/* <Button
                    className='d-block mt-3 mr-4'
                    variant='primary'
                    onClick={cargarFotoCredencial}>
                    Subir Foto
                  </Button> */}
                  <Button
                    className='d-block mt-3'
                    variant='primary'
                    onClick={capture}>
                    Tomar Foto
                  </Button>
                </div>
                <input
                  type='file'
                  accept='image/*'
                  name='fotoCredencial'
                  ref={credencialRef}
                  onChange={event => leerImagenCredencial(event)}
                  className='registro-credencial__input-file'
                />
                <input
                  type='file'
                  accept='image/*'
                  name='fotoCamaraCredencial'
                  capture='camera'
                  ref={camaraRef}
                  onChange={event => recuperarFotoCredencial(event)}
                  className='registro-credencial__input-file'
                />
              </div>
            </Col>
            <Col md={2} />
            <Col md={4}>
              <Form.Group>
                <Form.Label>Clave Electoral</Form.Label>
                <Form.Control
                  name='cve'
                  isInvalid={formik.errors.cve}
                  value={formik.values.cve}
                  onChange={formik.handleChange}
                  placeholder='Clave Electoral'
                  disabled
                />
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {formik.errors.cve}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Sección</Form.Label>
                <Form.Control
                  name='seccion'
                  isInvalid={formik.errors.seccion}
                  value={formik.values.seccion}
                  onChange={formik.handleChange}
                  placeholder='Sección'
                  disabled
                />
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {formik.errors.seccion}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className='regitro-credencial__seccion mt-3'>
          <div className='registro-credencial__cabecera'>
            <p className='registro-credencial__titulo-cabecera'>Nombre</p>
          </div>
          <Row className='mt-3'>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Apellido Paterno</Form.Label>
                <Form.Control
                  isInvalid={formik.errors.apellidoPaterno}
                  name='apellidoPaterno'
                  value={formik.values.apellidoPaterno}
                  onChange={formik.handleChange}
                  placeholder='Apellido Paterno'
                  disabled
                />
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {formik.errors.apellidoPaterno}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Apellido Materno</Form.Label>
                <Form.Control
                  isInvalid={formik.errors.apellidoMaterno}
                  name='apellidoMaterno'
                  value={formik.values.apellidoMaterno}
                  onChange={formik.handleChange}
                  placeholder='Apellido Materno'
                  disabled
                />
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {formik.errors.apellidoMaterno}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Nombre(s)</Form.Label>
                <Form.Control
                  isInvalid={formik.errors.nombre}
                  name='nombre'
                  value={formik.values.nombre}
                  onChange={formik.handleChange}
                  placeholder='Nombre(s)'
                  disabled
                />
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {formik.errors.nombre}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className='regitro-credencial__seccion mt-3'>
          <div className='registro-credencial__cabecera'>
            <p className='registro-credencial__titulo-cabecera'>Domicilio</p>
          </div>
          <Row className='mt-3'>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Código Postal</Form.Label>
                <Form.Control
                  isInvalid={formik.errors.codigoPostal}
                  name='codigoPostal'
                  value={formik.values.codigoPostal}
                  onChange={formik.handleChange}
                  placeholder='Código Postal'
                />
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {formik.errors.codigoPostal}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  isInvalid={formik.errors.estado}
                  name='estado'
                  value={formik.values.estado}
                  onChange={formik.handleChange}
                  placeholder='Estado'
                />
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {formik.errors.estado}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Municipio</Form.Label>
                <Form.Control
                  isInvalid={formik.errors.municipio}
                  name='municipio'
                  value={formik.values.municipio}
                  onChange={formik.handleChange}
                  placeholder='Municipio'
                />
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {formik.errors.municipio}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Colonia</Form.Label>
                <Form.Control
                  isInvalid={formik.errors.colonia}
                  name='colonia'
                  value={formik.values.colonia}
                  onChange={formik.handleChange}
                  placeholder='Colonia'
                  as='select'>
                  <option value={1}>Primer Colonia</option>
                  <option value={2}>Segunda Colonia</option>
                  <option value={3}>Tercera Colonia</option>
                </Form.Control>
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {formik.errors.colonia}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Calle</Form.Label>
                <Form.Control
                  isInvalid={formik.errors.calle}
                  name='calle'
                  value={formik.values.calle}
                  onChange={formik.handleChange}
                  placeholder='Calle'
                />
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {formik.errors.calle}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>No.Exterior</Form.Label>
                <Form.Control
                  isInvalid={formik.errors.numeroExterior}
                  name='numeroExterior'
                  value={formik.values.numeroExterior}
                  onChange={formik.handleChange}
                  placeholder='No.Exterior'
                />
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {formik.errors.numeroExterior}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={4}>
              <Form.Group>
                <Form.Label>No.Interior</Form.Label>
                <Form.Control
                  isInvalid={formik.errors.numeroInterior}
                  name='numeroInterior'
                  value={formik.values.numeroInterior}
                  onChange={formik.handleChange}
                  placeholder='No.Interior'
                />
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {formik.errors.numeroInterior}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  isInvalid={formik.errors.telefono}
                  name='telefono'
                  value={formik.values.telefono}
                  onChange={formik.handleChange}
                  placeholder='Teléfono'
                />
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {formik.errors.telefono}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={12} className='d-flex align-items-end justify-content-end'>
              <Button
                className='mx-4'
                variant='outline-primary'
                as={NavLink}
                to='/red'>
                Cancelar
              </Button>
              <Button variant='primary' type='submit'>
                Guardar
              </Button>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import TituloMovil from '../../../components/Shared/TituloMovil/TituloMovil';
import { OPCIONES_VOTOS } from '../../../constants/representanteGeneral';
import { useAutenticado } from '../../../hooks/useAutenticado';
import { detalleError } from '../../../utils/respuestas';
import axios from '../../../utils/axios';

import './OpcionesVotos.scss';

function OpcionesVotos() {
  const { idCasilla } = useParams();
  const datosUsuario = JSON.parse(useAutenticado());
  const [casilla, setCasilla] = useState({});

  const obtenerFiltroCasilla = async () => {
    try {
      const { data } = await axios.get(`Casilla/GetCasillasByUser`);
      const detalleCasilla = data.find(item => item.id === +idCasilla);
      setCasilla(detalleCasilla);
    } catch (ex) {
      detalleError(ex);
    }
  };

  const colocarSubTexto = tipoOpcion => {
    if (tipoOpcion === 'diputado federal')
      return `Distrito Federal ${casilla?.distritoFederalId}`;
    if (tipoOpcion === 'diputado local')
      return `Distrito Local ${casilla?.distritoLocalId}`;
    if (tipoOpcion === 'presidente') return `${casilla?.municipio}`;
    return '';
  };

  useEffect(() => {
    obtenerFiltroCasilla();
  }, []);

  return (
    <div className='opciones-votos'>
      <TituloMovil
        titulo='Opciones Votos'
        ruta={`/Representante/${idCasilla}`}
      />
      <p className='opciones-votos__subtitulo'>
        Representante General {datosUsuario?.usuario}
      </p>
      <div className='opciones-votos__contenedor'>
        {OPCIONES_VOTOS.map(opcion => (
          <Card
            className='opciones-votos__opcion'
            key={opcion.id}
            as={Link}
            to={`/representante/${idCasilla}/votos/${opcion.id}`}>
            <p className='opciones-votos__texto-opcion'>{opcion.tipo}</p>
            <p className='opciones-votos__subtexto-opcion'>
              {colocarSubTexto(opcion.tipo.toLowerCase())}
            </p>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default OpcionesVotos;

import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import axios from '../../utils/axios';
import TituloMovil from '../../components/Shared/TituloMovil/TituloMovil';
import { detalleError } from '../../utils/respuestas';
import { useAutenticado } from '../../hooks/useAutenticado';

import './Representante.scss';

function Representante() {
  const [casillas, setCasillas] = useState([]);
  const datosUsuario = JSON.parse(useAutenticado());

  const AbiertoCerradoCasilla = (casilla, propiedad) => {
    if (casilla.horaInicio && !casilla.horaCierre) {
      return {
        [propiedad]: '#28AD65',
      };
    }
    if (casilla.horaInicio && casilla.horaCierre) {
      return {
        [propiedad]: '#CE5353',
      };
    }
    return {
      [propiedad]: '#696969',
    };
  };

  const textoAbiertoCerradoCasilla = casilla => {
    if (casilla.horaInicio && !casilla.horaCierre) return 'Abierta';
    if (casilla.horaInicio && casilla.horaCierre) return 'Cerrada';
    return 'Inhabilitada';
  };

  const obtenerCasillas = async () => {
    try {
      const { data } = await axios.get(`Casilla/GetCasillasByUser`);
      setCasillas(data);
    } catch (ex) {
      detalleError(ex);
    }
  };

  useEffect(() => {
    obtenerCasillas();
  }, []);

  return (
    <div className='representante'>
      <TituloMovil titulo='Casillas Asignadas' />
      <p className='representante__subtitulo'>
        Representante General {datosUsuario?.usuario}
      </p>
      <div className='row d-flex align-justify-content-center'>
        <div className='d-flex align-items-center justify-content-around flex-wrap representante__contenedor'>
          {casillas.map(casilla => (
            <Card
              key={casilla.id}
              className='representante__card'
              as={Link}
              to={`/representante/${casilla.id}`}>
              <div className='representante__cabecera-casilla'>
                <div className='representante__estado-casilla'>
                  <div
                    className='representante__indicador-estado'
                    style={AbiertoCerradoCasilla(casilla, 'backgroundColor')}
                  />
                  <p
                    className='representante__texto-estado'
                    style={AbiertoCerradoCasilla(casilla, 'color')}>
                    {textoAbiertoCerradoCasilla(casilla)}
                  </p>
                </div>
              </div>
              <p className='representante__numero-casilla'>{casilla.casilla}</p>
              <p className='representante__detalle-casilla'>
                Sección {casilla.seccionId}
              </p>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Representante;

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

// Páginas
import AgregarAsistentes from './pages/Representante/Bingo/AgregarAsistente/AgregarAsistente';
import Apertura from './pages/Representante/Apertura/Apertura';
import AsignacionCasilla from './pages/AsignacionCasilla';
import AvanceBingo from './pages/Representante/Bingo/AnvanceBingo/AvanceBingo';
import Bingo from './pages/Representante/Bingo/Bingo';
import Cierre from './pages/Representante/Cierre/Cierre';
import DetalleCasilla from './pages/Votos/DetalleCasilla/DetalleCasilla';
import Estadisticas from './pages/Estadisticas/Estadisticas';
import Eventos from './pages/Eventos/Eventos';
import Grafica from './pages/Grafica/Grafica';
import Incidentes from './pages/Representante/Incidentes/Incidentes';
import Jornada from './pages/Representante/Jornada/Jornada';
import Login from './pages/Login/Login';
import Mapas from './pages/Mapas/Mapas';
import NoEncontrado from './pages/NoEncontrado';
import NuevoUsuario from './pages/Registro/NuevoUsuario';
import NuevoVoto from './pages/NuevoVoto';
import OpcionesCasilla from './pages/Representante/OpcionesCasilla/OpcionesCasilla';
import OpcionesVotos from './pages/Representante/OpcionesVotos/OpcionesVotos';
import Red from './pages/Red/Red';
import Registro from './pages/Registro/Registro';
import RegistroCredencial from './pages/RegistroCredencial/RegistroCredencial';
import RegistroVotos from './pages/Representante/RegistroVotos/RegistroVotos';
import Representante from './pages/Representante/Representante';
import Votos from './pages/Votos/Votos';
import BingoCasilla from './pages/Votos/BingoCasilla/BingoCasilla';

// Componentes
import Navbar from './components/Shared/Menu/Menu';
import Footer from './components/Shared/Footer/Footer';
import RutaPrivada from './components/RutaPrivada';

import { autenticacionServicio } from './services/atenticacion';
import { useAutenticado } from './hooks/useAutenticado';

import './App.scss';

function App() {
  const [autenticado, setAutenticado] = useState(false);
  const [usuarioActual, setUsuarioActual] = useState('');

  useEffect(() => {
    autenticacionServicio.estado.subscribe(valor => {
      setAutenticado(valor);
      setUsuarioActual(JSON.parse(useAutenticado()));
    });
  }, []);

  return (
    <Router>
      {autenticado && (
        <Navbar
          usuario={usuarioActual?.usuario}
          rol={usuarioActual?.roles[0]}
        />
      )}
      <div className='container pt-4'>
        <Switch>
          <Route exact path='/'>
            {!autenticado ? <Login /> : <Redirect to={usuarioActual?.ruta} />}
          </Route>
          <RutaPrivada exact path='/red' titulo='Red'>
            <Red />
          </RutaPrivada>
          <RutaPrivada
            exact
            path='/registro-credencial'
            titulo='Registro Credencial'>
            <RegistroCredencial />
          </RutaPrivada>
          <RutaPrivada exact path='/estadisticas' titulo='Estadisticas'>
            <Estadisticas />
          </RutaPrivada>
          <RutaPrivada exact path='/eventos' titulo='Eventos'>
            <Eventos />
          </RutaPrivada>
          <RutaPrivada exact path='/mapas' titulo='Mapas'>
            <Mapas />
          </RutaPrivada>
          <RutaPrivada exact path='/votos' titulo='Casillas'>
            <Votos />
          </RutaPrivada>
          <RutaPrivada
            exact
            path='/votos/:idSeccion/:casilla'
            titulo='Detalle Casilla'>
            <DetalleCasilla />
          </RutaPrivada>
          <RutaPrivada exact path='/votos/agregar' titulo='Agregar Votos'>
            <NuevoVoto />
          </RutaPrivada>
          <RutaPrivada
            exact
            path='/casillas/asignacion'
            titulo='Asignación Casillas'>
            <AsignacionCasilla />
          </RutaPrivada>
          <RutaPrivada exact path='/avance' titulo='Avance'>
            <Grafica />
          </RutaPrivada>
          <RutaPrivada
            exact
            path='/representante'
            titulo='Representante General'>
            <Representante />
          </RutaPrivada>
          <RutaPrivada
            exact
            path='/representante/:idCasilla'
            titulo='Representante'>
            <OpcionesCasilla />
          </RutaPrivada>
          <RutaPrivada
            exact
            path='/representante/:idCasilla/apertura'
            titulo='Representante'>
            <Apertura />
          </RutaPrivada>
          <RutaPrivada
            exact
            path='/representante/:idCasilla/jornada'
            titulo='Representante'>
            <Jornada />
          </RutaPrivada>
          <RutaPrivada
            exact
            path='/representante/:idCasilla/bingo'
            titulo='Representante'>
            <Bingo />
          </RutaPrivada>
          <RutaPrivada
            exact
            path='/representante/:idCasilla/votos'
            titulo='Representante'>
            <OpcionesVotos />
          </RutaPrivada>
          <RutaPrivada
            exact
            path='/representante/:idCasilla/votos/:tipoVoto'
            titulo='Representante'>
            <RegistroVotos />
          </RutaPrivada>
          <RutaPrivada
            exact
            path='/representante/:idCasilla/incidentes'
            titulo='Representante'>
            <Incidentes />
          </RutaPrivada>
          <RutaPrivada
            exact
            path='/representante/:idCasilla/cierre'
            titulo='Representante'>
            <Cierre />
          </RutaPrivada>
          <RutaPrivada exact path='/avance-bingo' titulo='Avance Bingo'>
            <AvanceBingo />
          </RutaPrivada>
          <RutaPrivada
            exact
            path='/avance-bingo/asistentes'
            titulo='Agregar Asistentes'>
            <AgregarAsistentes />
          </RutaPrivada>
          <RutaPrivada exact path='/usuarios' titulo='Registro Usuarios'>
            <Registro />
          </RutaPrivada>
          <RutaPrivada exact path='/usuarios/nuevo' titulo='Nuevo Usuario'>
            <NuevoUsuario />
          </RutaPrivada>
          <RutaPrivada exact path='/bingo/:idCasilla/:idSeccion' titulo='Bingo'>
            <BingoCasilla />
          </RutaPrivada>
          <Route path='*'>
            <NoEncontrado />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;

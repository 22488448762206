import axios from 'axios';

const baseURL = process.env.REACT_APP_API;

const instancia = axios.create({
  baseURL,
});

instancia.interceptors.request.use(
  response => {
    const credenciales = localStorage.getItem('credenciales');
    if (credenciales) {
      const objetoCredenciales = JSON.parse(credenciales);
      response.headers.Authorization = `Bearer ${objetoCredenciales.token}`;
    }
    return response;
  },
  error => Promise.reject(error)
);

export default instancia;

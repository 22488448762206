import React from 'react';
import { Spinner } from 'react-bootstrap';

import './LoadingOverlay.scss';

function LoadingOverlay() {
  return (
    <div className="align-items-center d-flex h-100 justify-content-center loading-overlay position-fixed w-100">
      <Spinner animation="border" role="status" variant="primary">
        <span className="sr-only">Cargando...</span>
      </Spinner>
    </div>
  );
}

export default LoadingOverlay;
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Alert, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import TituloMovil from '../../../components/Shared/TituloMovil/TituloMovil';
import {
  OPCIONES_VOTOS,
  PARTIDOS,
} from '../../../constants/representanteGeneral';
import { detalleError } from '../../../utils/respuestas';
import { FORM_ERRORS } from '../../../constants/mensajes';
import axios from '../../../utils/axios';

import './RegistroVotos.scss';

function RegistroVotos() {
  const history = useHistory();
  const { idCasilla, tipoVoto } = useParams();
  const [casilla, setCasilla] = useState({});
  const [plantilla, setPlantilla] = useState({});
  const [mensajeError, setMensajeError] = useState('');
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({ mode: 'onBlur' });

  const obtenerCandidatura = () =>
    OPCIONES_VOTOS.find(opcion => opcion.id === +tipoVoto)?.tipo;

  const obtenerRutaPlantilla = datosCasilla => {
    if (obtenerCandidatura().toLocaleLowerCase() === 'gobernador')
      return 'Resultado/GetPlantillaGobernador';
    if (obtenerCandidatura().toLocaleLowerCase() === 'diputado federal')
      return 'Resultado/GetPlantillaDistritoFederal';
    if (obtenerCandidatura().toLocaleLowerCase() === 'diputado local')
      return `Resultado/GetPlantillaDistritoLocal/${datosCasilla?.distritoLocalId}`;
    if (obtenerCandidatura().toLocaleLowerCase() === 'presidente')
      return `Resultado/GetPlantillaMunicipio/${datosCasilla?.municipioId}`;
    return '';
  };

  const obtenerRutaPost = () => {
    if (obtenerCandidatura().toLocaleLowerCase() === 'gobernador')
      return `Resultado/AddResultadoGobernadorByCasilla/${idCasilla}`;
    if (obtenerCandidatura().toLocaleLowerCase() === 'diputado federal')
      return `Resultado/AddResultadoDiputadoFederalByCasilla/${idCasilla}`;
    if (obtenerCandidatura().toLocaleLowerCase() === 'diputado local')
      return `Resultado/AddResultadoDiputadoLocalByCasilla/${idCasilla}`;
    if (obtenerCandidatura().toLocaleLowerCase() === 'presidente')
      return `Resultado/AddResultadoPresidenteMuniciaplByCasilla/${idCasilla}`;
    return '';
  };

  const obtenerPlantillaVotos = async detalle => {
    try {
      const ruta = obtenerRutaPlantilla(detalle);
      const { data } = await axios.get(ruta);
      setPlantilla(data);
    } catch (ex) {
      detalleError(ex);
    }
  };

  const obtenerFiltroCasilla = async () => {
    try {
      const { data } = await axios.get(`Casilla/GetCasillasByUser`);
      const detalleCasilla = data.find(item => item.id === +idCasilla);
      setCasilla(detalleCasilla);
      obtenerPlantillaVotos(detalleCasilla);
    } catch (ex) {
      detalleError(ex);
    }
  };

  const colocarSubtitulo = tipoOpcion => {
    if (tipoOpcion.toLowerCase() === 'diputado federal')
      return `Distrito Federal ${casilla?.distritoFederalId}`;
    if (tipoOpcion.toLowerCase() === 'diputado local')
      return `Distrito Local ${casilla?.distritoLocalId}`;
    if (tipoOpcion.toLowerCase() === 'presidente')
      return `${casilla?.municipio}`;
    return '';
  };

  const obtenerDetallePartido = llavePartido =>
    PARTIDOS.find(part => part.llave === llavePartido);

  const formatearFormData = votos => {
    let objetoVotos = {};
    const llaves = Object.keys(votos).map(llave => llave);
    for (let indice = 0; indice < llaves.length; indice += 1) {
      objetoVotos = {
        ...objetoVotos,
        ...{ [llaves[indice].toUpperCase()]: votos[llaves[indice]] },
      };
    }
    return objetoVotos;
  };

  const guardarVotaciones = async formData => {
    const resultados = formatearFormData({ ...plantilla, ...formData });
    try {
      await axios.post(obtenerRutaPost(), resultados);
      history.push(`/representante/${idCasilla}/votos`);
    } catch (ex) {
      setMensajeError(detalleError(ex)?.mensaje);
    }
  };

  useEffect(() => {
    obtenerFiltroCasilla();
  }, []);

  return (
    <div className='registro-votos'>
      <TituloMovil
        titulo={`Votos ${obtenerCandidatura()}`}
        ruta={`/representante/${idCasilla}/votos`}
      />
      <p className='registro-votos__subtitulo'>
        {colocarSubtitulo(obtenerCandidatura())}
      </p>
      {mensajeError && (
        <Alert
          className='mt-3'
          variant='danger'
          onClose={() => setMensajeError('')}
          dismissible>
          {mensajeError}
        </Alert>
      )}
      <div className='registro-votos__contenedor'>
        <Form onSubmit={handleSubmit(guardarVotaciones)}>
          {Object.keys(plantilla)?.map(partido => (
            <Form.Group key={partido} className='registro-votos__captura'>
              {obtenerDetallePartido(partido) ? (
                <>
                  <img
                    src={obtenerDetallePartido(partido)?.imagen}
                    className='registro-votos__logotipo'
                    alt={obtenerDetallePartido(partido)?.label}
                  />
                  <div className='registro-votos__campo'>
                    <Form.Label className='mb-1'>
                      {obtenerDetallePartido(partido)?.label}
                    </Form.Label>
                    <Form.Control
                      isInvalid={errors[partido]}
                      name={partido}
                      {...register(`${partido}`, {
                        required: FORM_ERRORS.REQUIRED,
                        valueAsNumber: true,
                      })}
                      type='number'
                      defaultValue={0}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors[partido]?.message}
                    </Form.Control.Feedback>
                  </div>
                </>
              ) : (
                <></>
              )}
            </Form.Group>
          ))}
          <Button type='submit' variant='secondary' block>
            Registrar Votos
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default RegistroVotos;

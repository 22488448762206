import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Card } from 'react-bootstrap';

import Titulo from '../../../components/Shared/Titulo/Titulo';

import axios from '../../../utils/axios';

import './BingoCasilla.scss';

function BingoCasilla() {
  const { idCasilla, idSeccion } = useParams();
  const [bingos, setBingos] = useState([]);

  const getBingos = async () => {
    const { data } = await axios.get(`/Bingo/GetBingosByCasilla/${+idCasilla}`);
    setBingos(data);
  }
  
  useEffect(() => {
    getBingos();
  }, [])

  return (
    <div className='bingos'>
      <Titulo titulo='Bingo' ruta='/votos' regresar/>
      <h6 className='bingos__subtitulo'>Casilla {idCasilla} - Sección {idSeccion}</h6>
      <div className='row mt-4 bingos__contenedor-casillas'>
        <Col sm={12} md={12} lg={12}>
          <div className='d-flex align-items-center flex-wrap'>
            {bingos.map(bingo => (
              <Card
                key={bingo.id}
                className='bingos__card'>
                <p className='bingos__numero-casilla'>{bingo.numeroBingo}</p>
              </Card>
            ))}
          </div>
        </Col>
      </div>
    </div>
  )
}

export default BingoCasilla;
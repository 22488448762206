import React, { useState } from 'react';
import { Alert, Card, Col, Form, Button, FormGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import axios from '../../utils/axios';
import { detalleError } from '../../utils/respuestas';
import { autenticacionServicio } from '../../services/atenticacion';
import { FORM_ERRORS } from '../../constants/mensajes';

import './Login.scss';

function Login() {
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({ mode: 'onBlur' });
  const [mensajeError, setMensajeError] = useState('');

  const iniciarSesion = async formData => {
    try {
      const { data } = await axios.post('auth/login', formData);
      autenticacionServicio.iniciarSesion(data);
    } catch (ex) {
      setMensajeError(detalleError(ex)?.mensaje);
    }
  };

  return (
    <div className='row d-flex align-items-center justify-content-center'>
      <Col sm={10} md={4} lg={4}>
        <Card className='p-4 my-auto mx-auto'>
          <h3 className='text-start'>Iniciar Sesión</h3>
          {mensajeError && (
            <Alert className='mt-3' variant='danger'>
              {mensajeError}
            </Alert>
          )}
          <Form onSubmit={handleSubmit(iniciarSesion)}>
            <Form.Group className='mt-3'>
              <Form.Label>Usuario</Form.Label>
              <Form.Control
                isInvalid={errors?.userName}
                name='userName'
                {...register('userName', { required: FORM_ERRORS.REQUIRED })}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.userName?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <br />
            <FormGroup>
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                isInvalid={errors?.password}
                name='password'
                type='password'
                {...register('password', { required: FORM_ERRORS.REQUIRED })}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.password?.message}
              </Form.Control.Feedback>
            </FormGroup>
            <br />
            <Button
              block
              className='mt-1 mb-3'
              variant='secondary'
              type='submit'>
              Inciar Sesión
            </Button>
          </Form>
        </Card>
      </Col>
    </div>
  );
}

export default Login;

import * as yup from 'yup';

import { FORM_ERRORS } from '../constants/mensajes';

export const validacionesRegistroCredencial = yup.object().shape({
  cve: yup.string().required(FORM_ERRORS.REQUIRED),
  seccion: yup.string().required(FORM_ERRORS.REQUIRED),
  apellidoPaterno: yup.string().required(FORM_ERRORS.REQUIRED),
  apellidoMaterno: yup.string(),
  nombre: yup.string().required(FORM_ERRORS.REQUIRED),
  codigoPostal: yup.string().required(FORM_ERRORS.REQUIRED),
  estado: yup.string().required(FORM_ERRORS.REQUIRED),
  municipio: yup.string().required(FORM_ERRORS.REQUIRED),
  colonia: yup.number().required(FORM_ERRORS.REQUIRED),
  calle: yup.string().required(FORM_ERRORS.REQUIRED),
  numeroExterior: yup.string().required(FORM_ERRORS.REQUIRED),
  numeroInterior: yup.string(),
  telefono: yup.string().required(FORM_ERRORS.REQUIRED),
});

import { BehaviorSubject } from 'rxjs';

const rutaInicial = roles => {
  if (roles.includes('Administrador') || roles.includes('Juridico'))
    return '/red';
  if (roles.includes('Oro')) return '/votos';
  if (roles.includes('Bingo')) return '/avance-bingo';
  if (roles.includes('Representante General')) return '/representante';
  return '/';
};

const estadoAutenticacion = new BehaviorSubject(
  localStorage.getItem('credenciales')
);

const iniciarSesion = valorAutenticacion => {
  const { usuario, token, roles } = valorAutenticacion;
  localStorage.setItem(
    'credenciales',
    JSON.stringify({ usuario, token, roles, ruta: rutaInicial(roles) })
  );
  estadoAutenticacion.next(true);
};

const cerrarSesion = () => {
  localStorage.removeItem('credenciales');
  estadoAutenticacion.next(false);
};

export const autenticacionServicio = {
  iniciarSesion,
  cerrarSesion,
  estado: estadoAutenticacion.asObservable(),
};

import React, { useState, useEffect } from 'react';
import { Col, Form, Button } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import TituloMovil from '../../../components/Shared/TituloMovil/TituloMovil';
import { detalleError } from '../../../utils/respuestas';
import { FORM_ERRORS } from '../../../constants/mensajes';
import LoadingOverlay from '../../../components/Shared/Loading/LoadingOverlay/LoadingOverlay';

import axios from '../../../utils/axios';
import { getFormData } from '../../../utils/FormData';

function Cierre() {
  const { idCasilla } = useParams();
  const history = useHistory();
  const [incidenciaCierreId, setIncidenciaCierreId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({ mode: 'onBlur' });

  const obtenerCatalogoIncidentes = async () => {
    try {
      const { data } = await axios.get(`Incidente/GetIncidenteCierreOptions`);
      const { incidencias } = data;
      setIncidenciaCierreId(incidencias[0]?.id);
    } catch (ex) {
      detalleError(ex);
    }
  };

  const cerrarCasilla = async formData => {
    try {
      setIsLoading(true);
      const incidente = getFormData({...formData, Files: [...formData.files], IncidenciaId: incidenciaCierreId  });

      await axios.post(
        `Incidente/AddIncidenteCierre/${idCasilla}`,
        incidente
      );
      history.push(`/representante`);
    } catch (ex) {
      detalleError(ex);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    obtenerCatalogoIncidentes();
  }, []);

  if(isLoading) return <LoadingOverlay />;

  return (
    <div className='apertura'>
      <TituloMovil
        titulo='Cierre Casilla'
        ruta={`/representante/${idCasilla}`}
      />
      <Form onSubmit={handleSubmit(cerrarCasilla)}>
        <div className='row mt-3 d-flex align-items-center justify-content-center'>
          <Col xs={11} sm={11} md={4}>
            <Form.Group>
              <Form.Label>Hora Cierre</Form.Label>
              <Form.Control
                isInvalid={errors?.hora}
                name='hora'
                type='time'
                {...register('hora', {
                  required: FORM_ERRORS.REQUIRED,
                })}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.hora?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </div>
        <div className='row mt-2 d-flex align-items-center justify-content-center'>
          <Col xs={11} sm={11} md={4}>
            <Form.Group controlId='imagenesIncidentes'>
              <Form.Label>Imágenes de cierre</Form.Label>
              <Form.Control
                accept='image/*'
                name='files'
                {...register('files')}
                type='file'
                multiple
              />
            </Form.Group>
          </Col>
        </div>
        <div className='row mt-2 d-flex align-items-center justify-content-center'>
          <Col xs={11} sm={11} md={4}>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                name='descripcion'
                as='textarea'
                {...register('descripcion')}
                rows={4}
              />
            </Form.Group>
          </Col>
        </div>
        <div className='row mt-2 d-flex align-items-center justify-content-center'>
          <Col xs={11} sm={11} md={4}>
            <Button variant='secondary' type='submit' block>
              Cerrar Casilla
            </Button>
          </Col>
        </div>
      </Form>
    </div>
  );
}

export default Cierre;
import React, { useState, useEffect } from 'react';
import { Alert, Button, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Titulo from '../../../../components/Shared/Titulo/Titulo';
import { FORM_ERRORS } from '../../../../constants/mensajes';
import { detalleError } from '../../../../utils/respuestas';
import axios from '../../../../utils/axios';

function AgregarAsistente() {
  const [promotores, setPromotores] = useState([]);
  const [mensajeError, setMensajeError] = useState('');
  const history = useHistory();
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({ mode: 'onBlur' });

  const guardarAsistentes = async formData => {
    try {
      await axios.put(
        `Promotor/UpdatePromotorAsistentes/${formData.promotor}`,
        { asistentes: formData.asistentes }
      );
      history.push('/avance-bingo');
    } catch (ex) {
      setMensajeError(detalleError(ex)?.mensaje);
    }
  };

  const obtenerPromotores = async () => {
    try {
      const { data } = await axios.get('Promotor/GetAllPromotores');
      setPromotores(data);
    } catch (ex) {
      detalleError(ex);
    }
  };

  useEffect(() => {
    obtenerPromotores();
  }, []);

  return (
    <div>
      <Titulo
        className='mb-4'
        titulo='Agregar Asistentes'
        ruta='/avance-bingo'
        regresar
      />
      {mensajeError && (
        <div className='row'>
          <Col xs={12} sm={12} md={9}>
            <Alert
              className='mt-3'
              variant='danger'
              onClose={() => setMensajeError('')}
              dismissible>
              {mensajeError}
            </Alert>
          </Col>
        </div>
      )}
      <Form
        className='row d-flex align-items-end mt-5'
        onSubmit={handleSubmit(guardarAsistentes)}>
        <Col xs={12} sm={12} md={3}>
          <Form.Group className='mb-0 mr-3'>
            <Form.Label>Promotor</Form.Label>
            <Form.Control
              isInvalid={errors?.promotor}
              as='select'
              name='promotor'
              {...register('promotor', {
                required: FORM_ERRORS.REQUIRED,
                valueAsNumber: true,
              })}>
              <option value=''>Seleccione un promotor</option>
              {promotores?.map(promotor => (
                <option key={promotor.id} value={promotor.id}>
                  {promotor.nombre}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              {errors?.promotor?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={3}>
          <Form.Group className='mb-0 mr-3'>
            <Form.Label>Asistentes</Form.Label>
            <Form.Control
              isInvalid={errors?.asistentes}
              name='asistentes'
              {...register('asistentes', {
                required: FORM_ERRORS.REQUIRED,
                valueAsNumber: true,
              })}
              defaultValue={0}
            />
            <Form.Control.Feedback type='invalid'>
              {errors?.asistentes?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={3} className='pl-0'>
          <Button variant='secondary' type='submit'>
            Guardar Asistente
          </Button>
        </Col>
      </Form>
    </div>
  );
}

export default AgregarAsistente;

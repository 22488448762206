import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { HorizontalBar, Pie } from 'react-chartjs-2';
import * as signalR from '@microsoft/signalr';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-labels';

import Titulo from '../../components/Shared/Titulo/Titulo';
import { detalleError } from '../../utils/respuestas';
import axios from '../../utils/axios';

import './Grafica.scss';

function Grafica() {
  const [conexion, setConexion] = useState(null);
  const [votosConteo, setVotosConteo] = useState({});
  const [municipiosUno, setMunicipiosUno] = useState([]);
  const [municipiosDos, setMunicipiosDos] = useState([]);
  const primerosMunicipios = [
    `ARMERÍA - Casillas Computadas ${
      +municipiosUno[0]?.porcentajeCasillasComputadas * 100
    }%`,
    `COLIMA - Casillas Computadas ${
      +municipiosUno[1]?.porcentajeCasillasComputadas * 100
    }%`,
    `COMALA - Casillas Computadas ${
      +municipiosUno[2]?.porcentajeCasillasComputadas * 100
    }%`,
    `COQUIMATLÁN - Casillas Computadas ${
      +municipiosUno[3]?.porcentajeCasillasComputadas * 100
    }%`,
    `CUAUHTÉMOC - Casillas Computadas ${
      +municipiosUno[4]?.porcentajeCasillasComputadas * 100
    }%`,
  ];
  const segundosMunicipios = [
    `IXTLAHUACÁN - Casillas Computadas ${
      +municipiosDos[0]?.porcentajeCasillasComputadas * 100
    }%`,
    `MANZANILLO - Casillas Computadas ${
      +municipiosDos[1]?.porcentajeCasillasComputadas * 100
    }%`,
    `MINATITLÁN - Casillas Computadas ${
      +municipiosDos[2]?.porcentajeCasillasComputadas * 100
    }%`,
    `TECOMÁN - Casillas Computadas ${
      +municipiosDos[3]?.porcentajeCasillasComputadas * 100
    }%`,
    `VILLA DE ÁLVAREZ - Casillas Computadas ${
      +municipiosDos[4]?.porcentajeCasillasComputadas * 100
    }%`,
  ];

  const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    style: 'decimal',
  });

  const formatoNumero = valor => {
    if (typeof valor !== 'number') return valor;
    return numberFormatter.format(valor);
  };

  const opcionesVotos = {
    legend: false,
    title: {
      display: true,
      text: `Casillas Computadas ${
        +votosConteo.porcentajeCasillasComputadas * 100
      }% - Votos Computados ${+votosConteo.porcentajeVotosComputados * 100}%`,
      fontSize: 17,
    },
    tooltips: {
      callbacks: {
        label: ({ index, datasetIndex }, { datasets }) => {
          const { data } = datasets[datasetIndex];
          const partidos = [
            'Va x Colima',
            'Partido Verde',
            'Movimiento Ciudadano',
            'Morena',
            'Otros',
          ];
          return `${partidos[index]}: ${formatoNumero(data[index])}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: value => `${value}`,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
          },
          barThickness: 50,
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: value => `${formatoNumero(value)}`,
        align: 'start',
        anchor: 'end',
        clip: true,
        font: {
          size: '14',
          weight: '400',
        },
        color: 'white',
        display: 'auto',
      },
    },
  };

  const opcionesMunicipio = municipio => {
    return {
      legend: false,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: municipio,
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: ({ index, datasetIndex }, { datasets, labels }) => {
            const { data } = datasets[datasetIndex];
            return `${labels[index]}: ${formatoNumero(data[index])}`;
          },
        },
      },
      plugins: {
        labels: [
          {
            render: ({ percentage }) => `${percentage}%`,
            fontSize: 12,
            fontColor: '#FFFFFF',
          },
        ],
        datalabels: {
          display: false,
        },
      },
    };
  };

  const progresoVotos = {
    labels: ['', '', '', '', ''],
    datasets: [
      {
        backgroundColor: [
          '#2250A6',
          '#50B747',
          '#FF8300',
          '#B5261E',
          '#9B9B9B',
        ],
        borderWidth: 0,
        data: [
          votosConteo.vaXColima,
          votosConteo.verde,
          votosConteo.mc,
          votosConteo.morenA_NA,
          votosConteo.otros,
        ],
      },
    ],
  };

  const progresoMunicipio = (vaXColima, verde, mc, morena, otros) => {
    return {
      labels: [
        'Va x Colima',
        'Partido Verde',
        'Movimiento Ciudadano',
        'Morena',
        'Otros',
      ],
      datasets: [
        {
          data: [vaXColima, verde, mc, morena, otros],
          backgroundColor: [
            '#2250A6',
            '#50B747',
            '#FF8300',
            '#B5261E',
            '#9B9B9B',
          ],
          borderColor: ['#2250A6', '#50B747', '#FF8300', '#B5261E', '#9B9B9B'],
          borderWidth: 1,
        },
      ],
    };
  };

  const obtenerResultados = () => {
    const conexionSR = new signalR.HubConnectionBuilder()
      .withUrl('/dashBoardHub')
      .withAutomaticReconnect()
      .build();
    setConexion(conexionSR);
  };

  const obtenerConteoRapido = async () => {
    try {
      const { data } = await axios.get('Resultado/GetConteoRapidoGobernador');
      setVotosConteo(data);
      setMunicipiosUno(data.municipios.slice(0, 5));
      setMunicipiosDos(data.municipios.slice(5, 10));
      obtenerResultados();
    } catch (ex) {
      detalleError(ex);
    }
  };

  const escucharConexion = () => {
    if (conexion) {
      conexion
        .start()
        .then(() => {
          conexion.on('UpdateConteoRapidoGobernador', data => {
            setVotosConteo(data);
            setMunicipiosUno(data.municipios.slice(0, 5));
            setMunicipiosDos(data.municipios.slice(5, 10));
          });
        })
        .catch(error => console.log(error));
    }
  };

  useEffect(() => {
    obtenerConteoRapido();
    return () => {
      if (conexion) {
        conexion.off('nuevosResultados');
        conexion.stop();
      }
    };
  }, []);

  useEffect(() => {
    escucharConexion();
    return () => {
      if (conexion) {
        conexion.off('nuevosResultados');
        conexion.stop();
      }
    };
  }, [conexion]);

  return (
    <div className='grafica'>
      <Titulo titulo='Elección Gobernador Colima 2021 - Conteo Rápido' />
      <div className='d-flex align-items-center justify-content-end row'>
        <Col
          className='grafica__logos d-flex align-items-end justify-content-around flex-column px-1 mx-0'
          md={1}
          lg={1}>
          <img
            src='/iconos/vaxcolima.png'
            className='grafica__logo-partido'
            alt='Va x Colima'
          />
          <img
            src='/iconos/pvem.png'
            className='grafica__logo-partido'
            alt='Partido Verde'
          />
          <img
            src='/iconos/movciu.png'
            className='grafica__logo-partido'
            alt='Movimiento Ciudadano'
          />
          <img
            src='/iconos/morena.png'
            className='grafica__logo-partido'
            alt='Morena'
          />
          <div className='grafica__otros d-flex align-items-center justify-content-center'>
            Otros
          </div>
        </Col>
        <Col className='px-0 mx-0' md={11} lg={11}>
          <HorizontalBar
            data={progresoVotos}
            options={opcionesVotos}
            height={100}
          />
        </Col>
      </div>
      <div className='mt-3 row'>
        <Col
          className='d-flex align-items-center justify-content-between'
          md={12}
          lg={12}>
          {municipiosUno.map((municipio, indice) => (
            <div key={municipio.municipioId}>
              <Pie
                data={progresoMunicipio(
                  municipio.vaXColima,
                  municipio.verde,
                  municipio.mc,
                  municipio.morenA_NA,
                  municipio.otros
                )}
                options={opcionesMunicipio(primerosMunicipios[indice])}
                height={190}
                width={190}
              />
            </div>
          ))}
        </Col>
      </div>
      <div className='mt-3 row'>
        <Col
          className='d-flex align-items-center justify-content-between'
          md={12}
          lg={12}>
          {municipiosDos.map((municipio, indice) => (
            <div key={municipio.municipioId}>
              <Pie
                data={progresoMunicipio(
                  municipio.vaXColima,
                  municipio.verde,
                  municipio.mc,
                  municipio.morenA_NA,
                  municipio.otros
                )}
                options={opcionesMunicipio(segundosMunicipios[indice])}
                height={190}
                width={190}
              />
            </div>
          ))}
        </Col>
      </div>
    </div>
  );
}

export default Grafica;

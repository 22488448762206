import React from 'react';

import './Footer.scss';

function Footer() {
  return (
    <footer className='container d-flex justify-content-center mt-4 mb-2 footer'>
      <div className='border-top w-100 text-center py-2'>
        <p className='text-center footer__texto'>
          &copy; Elecciones {new Date().getFullYear()} - Derechos Reservados MSW
        </p>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useEffect, useState } from 'react';
import { Button, Col, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import * as signalR from '@microsoft/signalr';

import { detalleError } from '../../../../utils/respuestas';
import { useAutenticado } from '../../../../hooks/useAutenticado';
import axios from '../../../../utils/axios';

import './AvanceBingo.scss';

function AvanceBingo() {
  const [datosBingo, setDatosBingo] = useState({});
  const [conexion, setConexion] = useState(null);

  const rolUsuario = () =>
    JSON.parse(useAutenticado())?.roles.includes('Bingo') ||
    JSON.parse(useAutenticado())?.roles.includes('Administrador');

  const inicializarSignalR = () => {
    const conexionSR = new signalR.HubConnectionBuilder()
      .withUrl('/dashBoardHub')
      .withAutomaticReconnect()
      .build();
    setConexion(conexionSR);
  };

  const actualizarAvanceBingo = () => {
    if (conexion) {
      conexion
        .start()
        .then(() => {
          conexion.on('PromotorWasUpdated', data => {
            setDatosBingo(data);
          });
        })
        .catch(error => console.log(error));
    }
  };

  const obtenerResultadosBingo = async () => {
    try {
      const { data } = await axios.get('Promotor/GetAllPromotoresAndTotal');
      setDatosBingo(data);
      inicializarSignalR();
    } catch (ex) {
      detalleError(ex);
    }
  };

  useEffect(() => {
    obtenerResultadosBingo();
    return () => {
      if (conexion) {
        conexion.off('PromotorWasUpdated');
        conexion.stop();
      }
    };
  }, []);

  useEffect(() => {
    actualizarAvanceBingo();
    return () => {
      if (conexion) {
        conexion.off('PromotorWasUpdated');
        conexion.stop();
      }
    };
  }, [conexion]);

  return (
    <div className='avance-bingo'>
      <h2 className='text-center m-0'>Avance Bingo</h2>
      {rolUsuario() && (
        <div className='row mt-0 d-flex align-items-center justify-content-end mx-1'>
          <Button
            variant='secondary'
            as={NavLink}
            to='/avance-bingo/asistentes'>
            Agregar Asistente
          </Button>
        </div>
      )}
      <div className='row mt-2'>
        <Col xs={12} sm={12} md={12}>
          <Table responsive striped hover>
            <thead>
              <tr>
                <th>Promotor</th>
                <th>Meta</th>
                <th>Asistentes</th>
                <th>Avance %</th>
              </tr>
            </thead>
            <tbody>
              {datosBingo?.promotores?.map(municipio => (
                <tr key={municipio.id}>
                  <td className='avance-bingo__columna'>{municipio.nombre}</td>
                  <td className='avance-bingo__columna-chica'>
                    {municipio.meta}
                  </td>
                  <td className='avance-bingo__columna-chica'>
                    {municipio.asistentes}
                  </td>
                  <td>
                    <div className='avance-bingo__fondo-progreso'>
                      <div
                        className='avance-bingo__progreso'
                        style={{
                          width: `${municipio.porcentaje}%`,
                        }}>
                        <p className='avance-bingo__cantidad'>
                          {municipio.porcentaje}%
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td className='avance-bingo__columna font-weight-bold'>
                  Total
                </td>
                <td className='avance-bingo__columna-chica font-weight-bold'>
                  {datosBingo?.total?.meta}
                </td>
                <td className='avance-bingo__columna-chica font-weight-bold'>
                  {datosBingo?.total?.asistentes}
                </td>
                <td>
                  <div className='avance-bingo__fondo-progreso'>
                    <div
                      className='avance-bingo__progreso-total'
                      style={{
                        width: `${datosBingo?.total?.porcentaje}%`,
                      }}>
                      <p className='avance-bingo__cantidad font-weight-bold'>
                        {datosBingo?.total?.porcentaje}%
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </div>
    </div>
  );
}

export default AvanceBingo;

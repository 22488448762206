import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import PropTypes from 'prop-types';

import './TituloMovil.scss';

function TituloMovil({ ruta, titulo }) {
  return (
    <div className='d-flex align-items-center row my-2 titulo-movil'>
      {ruta && (
        <Col xs={2} sm={2} md={2} lg={2}>
          <Button startIcon={<ArrowBack />} component={Link} to={ruta} />
        </Col>
      )}
      <Col
        xs={ruta ? 8 : 12}
        sm={ruta ? 8 : 12}
        md={ruta ? 8 : 12}
        lg={ruta ? 8 : 12}>
        <h2 className='titulo-movil__titulo'>{titulo}</h2>
      </Col>
    </div>
  );
}

TituloMovil.defaultProps = {
  ruta: undefined,
};

TituloMovil.propTypes = {
  ruta: PropTypes.string,
  titulo: PropTypes.string.isRequired,
};

export default TituloMovil;
